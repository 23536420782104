import DataGrid from "../../components/DataGrid";
import PageView from "../../components/PageView";
import {useState} from "react";
import {Button, Card} from "@material-tailwind/react";
import DefaultInput from "../../components/Form/DefaultInput";
import FormButtons from "../../components/Form/FormButtons";
import {submit} from "../../common/Form";
import Modal from "../../components/Modal";
import EditableColumns from "../../components/EditableColumns";
import DefaultCellRenderer from "../../components/DataGrid/DefaultCellRenderer";
import DefaultEditCellRenderer from "../../components/DataGrid/EditCells/DefaultEditCellRenderer";
import DeleteCell from "../../components/DataGrid/DeleteCell";
import {remove} from "../../common/Supabase";
import {emitCustomEvent} from "react-custom-events";
import DefaultHeaderCellRendererWithFilters from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRendererWithFilters";

export default function Category({ session }) {
    const [showModal, setShowModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({})

    const table = 'Category'

    const rules = {
        category_name: {required: true, maxLength: 255, minLength: 3},
    }

    const columns = [
        {
            key: 'category_name',
            name: 'Name',
            type: 'text',
            sortable: true,
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
    ];

    const gridColumns = [
        ...columns,
        DeleteCell({
            onDelete: async (id) => {
                const {error} = await remove(table, 'id', id)
                if (error) {
                    alert(error.details)
                }
                emitCustomEvent('refresh.DataGrid.' + table)
            },
            canClearFilters: true
        })
    ]

    return (<PageView>
        <Modal
            loading={modalLoading}
            show={showModal}
            className={'bg-white px-6 py-10'}
            confirmText={'Submit'}
            header={'Add Out Of Stock'}
            onClose={() => setShowModal(false)}
        >
            <Card color="transparent" shadow={false}>
                <form className="w-full">
                    <div className="mb-4 flex flex-col gap-6">
                        <DefaultInput
                            label={columns[0].name}
                            name={columns[0].key}
                            rules={columns[0].rules}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <FormButtons
                            onSubmit={() =>
                                submit(
                                    table,
                                    form,
                                    rules,
                                    setErrors,
                                    setModalLoading,
                                    setShowModal,
                                    () => {
                                        emitCustomEvent('refresh.DataGrid.' + table)
                                    }
                                )
                            }
                            onClose={() => setShowModal(false)}
                            loading={modalLoading}
                        />
                    </div>
                </form>
            </Card>
        </Modal>
        <Button onClick={() => setShowModal(true)} color="amber" className="my-2 ml-2">Add</Button>
        <EditableColumns columns={columns} table={table}/>
        <DataGrid
            table={table}
            columns={gridColumns}
        />
    </PageView>)
}
