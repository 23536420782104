import DataGrid from "../../components/DataGrid";
import PageView from "../../components/PageView";
import {useState} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, tab, Typography} from "@material-tailwind/react";
import DefaultInput from "../../components/Form/DefaultInput";
import SelectInput from "../../components/Form/SelectInput";
import FormButtons from "../../components/Form/FormButtons";
import Modal from "../../components/Modal";
import DefaultCellRenderer from "../../components/DataGrid/DefaultCellRenderer";
import {create, get, remove} from "../../common/Supabase";
import ImageInput from "../../components/Form/ImageInput";
import {validate} from "../../common/Form";
import {supabase} from "../../supabaseClient";
import EditableColumns from "../../components/EditableColumns";
import ImageCellRenderer from "../../components/DataGrid/ImageCellRenderer";
import DefaultEditCellRenderer from "../../components/DataGrid/EditCells/DefaultEditCellRenderer";
import ImageEditCellRenderer from "../../components/DataGrid/EditCells/ImageEditCellRenderer";
import DeleteCell from "../../components/DataGrid/DeleteCell";
import { emitCustomEvent } from 'react-custom-events'
import ImagePreviewModal from "../../components/ImagePreviewModal";
import DefaultHeaderCellRendererWithFilters
    from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRendererWithFilters";
import DefaultHeaderCellRenderer from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRenderer";
import ProductCodeCellRenderer from "../../components/DataGrid/ProductCodeCellRenderer";
import ChildDataGrid from "../../components/ChildDataGrid";

export default function Products({session}) {
    const [showModal, setShowModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(true)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({})
    const [formImages, setFormImages] = useState({})
    const [brandOptions, setBrandOptions] = useState([])
    const [categoryOptions, setCategoryOptions] = useState([])
    const [showImagePreviewModal, setShowImagePreviewModal] = useState(false)
    const [imagePreviewUrl, setImagePreviewUrl] = useState('')

    //Related info
    const [productId, setProductId] = useState(null)
    const [showProductDetailsModal, setShowProductDetailsModal] = useState(false)
    const [productDetailsModalLoading, setProductDetailsModalLoading] = useState(true)



    const table = 'Products'

    const select = 'id, product_code, product_image, product_name_hk, product_eng_name, barcode, order_qty, Brand(brand_name), Category(category_name)'

    const columns = [
        {
            key: 'product_code',
            name: 'PRODUCT CODE',
            type: 'text',
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
            renderCell: ProductCodeCellRenderer,
            onProductCodeClick(productId) {
                setProductId(productId)
                setShowProductDetailsModal(true)
            }
        },
        {
            key: 'product_image',
            name: 'PRODUCT IMAGE',
            type: 'image',
            table: table,
            renderCell: ImageCellRenderer,
            renderEditCell: ImageEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRenderer,
            cellClass() {
                return 'p-0';
            },
            onImageEnlarge(url) {
                setImagePreviewUrl(url)
                setShowImagePreviewModal(true)
            }
        },
        {
            key: 'product_name_hk',
            name: 'PRODUCT HK NAME',
            type: 'text',
            sortable: true,
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'product_eng_name',
            name: 'PRODUCT ENG NAME',
            type: 'text',
            sortable: true,
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'barcode',
            name: 'BARCODE',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'order_qty',
            name: 'ORDER QTY',
            type: 'number',
            renderCell: DefaultCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'created_at',
            name: 'Created At',
            type: 'date',
            sortable: true,
            renderCell: DefaultCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
    ];

    const rules = {
        product_name_hk: {required: true, maxLength: 255, minLength: 3},
        product_eng_name: {required: true, maxLength: 255, minLength: 3},
        barcode: {required: true, maxLength: 255, minLength: 3},
        brand_id: {required: true},
        category_id: {required: true},
    }

    async function submit() {
        const errors = validate(form, rules)
        setErrors(errors)

        if (Object.keys(errors).length > 0) {
            return
        }

        setModalLoading(true)

        let dataToInsert = form

        if (formImages.product_image) {
            const extension = formImages.product_image.name.split('.').pop()
            let name = formImages.product_image.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '_')

            name = name + '_' + Date.now()

            const {data, error} = await supabase
                .storage
                .from('mb_images')
                .upload('Tables/' + table + '/' + name + '.' + extension, formImages.product_image, {
                    cacheControl: '3600',
                    upsert: false
                })

            const res = await supabase
                .storage
                .from('mb_images')
                .getPublicUrl(data.path)

            dataToInsert['product_image'] =  res.data.publicUrl.replace(process.env.REACT_APP_SUPABASE_URL, '')
        }

        await create(table, dataToInsert)
        emitCustomEvent('refresh.DataGrid.' + table)

        setModalLoading(false)
        setShowModal(false)
    }

    function openModal() {
        setShowModal(true)
        setModalLoading(true)
        fetchAllOptions().then(() => setModalLoading(false))
    }

    async function fetchAllOptions(table, select) {
        await get('Category', 'id, category_name')
            .then(data => setCategoryOptions(data))

        await get('Brand', 'id, brand_name, created_at')
            .then(data => setBrandOptions(data))
    }

    const gridColumns = [
        ...columns,
        {
            key: 'Brand.brand_name',
            name: 'BRAND NAME',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderHeaderCell: DefaultHeaderCellRenderer,
        },
        {
            key: 'Category.category_name',
            name: 'CATEGORY NAME',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderHeaderCell: DefaultHeaderCellRenderer,
        },
        DeleteCell({
            onDelete: async (id) => {
                const {error} = await remove(table, 'id', id)
                if (error) {
                    alert(error.details)
                }
                emitCustomEvent('refresh.DataGrid.' + table)
            },
            canClearFilters: true
        })
    ]

    return (<PageView>
        <Modal
            loading={modalLoading}
            show={showModal}
            className={'bg-white px-6 py-10'}
            confirmText={'Submit'}
            header={'Add Product'}
            onClose={() => setShowModal(false)}
        >
            <Card color="transparent" shadow={false}>
                <form className="w-full">
                    <div className="mb-4 flex flex-col gap-6">
                        <ImageInput
                            label={columns[1].name}
                            name={columns[1].key}
                            rules={columns[1].rules}
                            onChange={(name, value) => setFormImages({...formImages, [name]: value})}
                            errors={errors}
                        />
                        <DefaultInput
                            label={columns[2].name}
                            name={columns[2].key}
                            rules={columns[2].rules}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <DefaultInput
                            label={columns[3].name}
                            name={columns[3].key}
                            rules={columns[3].rules}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <DefaultInput
                            label={columns[4].name}
                            name={columns[4].key}
                            rules={columns[4].rules}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <SelectInput
                            name={'category_id'}
                            label={'Category'}
                            table={'Category'}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                            options={categoryOptions}
                            labelKeys={['category_name', 'created_at']}
                        />
                        <SelectInput
                            name={'brand_id'}
                            label={'Brand'}
                            table={'Brand'}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                            options={brandOptions}
                            labelKeys={['brand_name', 'created_at']}
                        />
                        <FormButtons
                            onSubmit={submit}
                            onClose={() => setShowModal(false)}
                            loading={modalLoading}
                        />
                    </div>
                </form>
            </Card>
        </Modal>
        <Button onClick={() => {
            openModal()
        }} color="amber" className="my-2 ml-2">Add</Button>
        <EditableColumns columns={columns.filter((c) => c.key !== 'order_qty')} table={table}/>
        <DataGrid table={table} columns={gridColumns} select={select}/>
        <ImagePreviewModal
            show={showImagePreviewModal}
            image={imagePreviewUrl}
            onClose={() => setShowImagePreviewModal(false)}
        />
        <Modal
            loading={productDetailsModalLoading}
            show={showProductDetailsModal}
            className={'bg-white px-6 py-10'}
            confirmText={'Submit'}
            header={'Product Details'}
            onClose={() => setShowProductDetailsModal(false)}
        >
            <Card className="mt-6 w-full">
                <CardBody>
                    <h2 className="font-bold">Out Of Stock</h2>
                    <ChildDataGrid
                        table={'OutOfStock'}
                        parentId={productId}
                        parentKey={'product_id'}
                        columns={[
                            {
                                key: 'quantity',
                                name: 'QTY',
                                type: 'number',
                                renderHeaderCell: ({column, ...q}) => DefaultHeaderCellRendererWithFilters({column, ...q}, 'ChildDataGrid'),
                            },
                            {
                                key: 'received_date',
                                name: 'Received Date',
                                type: 'date',
                                renderHeaderCell: ({column, ...q}) => DefaultHeaderCellRendererWithFilters({column, ...q}, 'ChildDataGrid'),
                            },
                            {
                                key: 'status',
                                name: 'Status',
                                type: 'text',
                                renderHeaderCell: ({column, ...q}) => DefaultHeaderCellRendererWithFilters({column, ...q}, 'ChildDataGrid'),
                            },
                            // DeleteCell({
                            //     onDelete: async (id, row) => {
                            //         const {error} = await remove('PurchaseOrderLineReceived', 'id', id)
                            //         if (error) {
                            //             alert(error.details)
                            //         }
                            //         emitCustomEvent('refresh.ChildDataGrid.' + row.parentId)
                            //         emitCustomEvent('refresh.DataGrid.' + table)
                            //     }
                            // })
                        ]}
                    />
                </CardBody>
            </Card>
        </Modal>
    </PageView>)
}
