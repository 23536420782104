import {useEffect, useState} from "react";

export default function DefaultCellRenderer({column, row})
{
    const [value, setValue] = useState(null);
    useEffect(() => {
        //todo: handle nested columns in DataGrid component
        const split = column.key.split('.')

        let val1 = row[split[0]];

        if (val1 !== null && typeof val1 === 'object') {
            val1 = val1[split[1]] ?? null;
        }

        setValue(val1)

    }, []);

    return (<span className="pl-2">{value}</span>)
}
