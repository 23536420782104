import {useState} from "react";
import {emitCustomEvent, useCustomEventListener} from "react-custom-events";

export default function DeleteCell({onDelete, buttonText = 'DELETE', canClearFilters = false}) {
    const [loading, setLoading] = useState(false)
    const [showClearFilters, setShowClearFilters] = useState(false)

    const data = {
        key: 'delete',
        type: 'delete',
        name: '',
        isCellEditable: false,
        width: 80,
        cellClass: 'p-0',
        renderCell: ({row}) => {
            return <button
                disabled={loading}
                className="disabled:opacity-50 w-20 h-full hover:bg-red-600"
                onClick={() => {
                    setLoading(true)
                    onDelete(row.id, row).then(() => {
                        setLoading(false)
                    })
                }}
            >
                {buttonText}
            </button>
        }
    }

    if (canClearFilters) {
        data.renderHeaderCell = () => {
            return <div className='h-full'>
                <div className='border-b-2 p-2'>
                    ‎
                </div>
                <button
                    disabled={loading}
                    className={`disabled:opacity-50 w-20 h-10 hover:bg-red-600 font-normal ${showClearFilters ? 'block' : 'hidden'}`}
                    onClick={() => clearFilters()}
                >
                    CLEAR
                </button>
            </div>
        }
    }

    useCustomEventListener('DataGrid.FilterUpdated', (data) => {
        if (!showClearFilters) {
            setShowClearFilters(true)
        }
    })

    function clearFilters()
    {
        emitCustomEvent('DataGrid.ClearFilters')
        setShowClearFilters(false)
    }

    return data
}
