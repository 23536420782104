import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import SelectCellRenderer from "../SelectCellRenderer";
import {emitCustomEvent} from "react-custom-events";

export default function SelectEditCellRenderer({column, row}) {
    const [editable, setEditable] = useState(false)
    const editables = useSelector((state) => state.editableColumns ?? [])

    useEffect(() => {
        setEditable(editables.find((c) => c.column === column.key && c.editable))
    }, []);

    if (!editable) {
        return <SelectCellRenderer column={column} row={row}/>
    }
    return (<select
        autoFocus
        className="w-full pl-2"
        defaultValue={row[column.key] ?? ''}
        onBlur={(event) => emitCustomEvent('DataGrid.RowUpdated', {
            id: row.id,
            key: column.key,
            value: event.target.value,
        })}
    >
        {
            column.options.map((option) => {
                return <option key={option.value} value={option.value}>{option.label}</option>
            })
        }
    </select>)
}
