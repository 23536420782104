export const SET_PROFILE = 'SET_PROFILE';
export const SET_USER = 'SET_USER';


const actionTypes = {
    SET_PROFILE,
    SET_USER
}

export default actionTypes;
