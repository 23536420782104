import SelectOption from "@material-tailwind/react/components/Select/SelectOption";
import {Select} from "@material-tailwind/react";
import SelectInput from "./SelectInput";
import {useEffect, useState} from "react";
import {supabase} from "../../supabaseClient";

export default function SupabaseSelectInput(
    {
        label,
        name,
        defaultValue,
        table,
        multiple = false,
        select = '*',
        labelKeys = [],
        onChange,
        errors,
        className = null
    }
) {
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        supabase.from(table)
            .select(select)
            .then(({data, error}) => {
                if (error) {
                    alert(error)
                } else {
                    setOptions(data)
                }
                setLoading(false)
            })
    }, []);
    return (
        loading
            ? <div>Loading...</div>
            : <SelectInput
                className={className}
                label={label}
                name={name}
                multiple={multiple}
                defaultValue={defaultValue}
                options={options}
                labelKeys={labelKeys}
                onChange={onChange}
                errors={errors}
            />)
}
