import {Input} from "@material-tailwind/react";
import Errors from "./Errors";

export default function DefaultInput(
    {
        label = null,
        type = 'text',
        name = null,
        onChange,
        defaultValue = null,
        errors,
        className = null,
        disabled = false,
    }) {
    return (<>
        <Input
            disabled={disabled}
            className={className}
            defaultValue={defaultValue}
            name={name}
            size="lg"
            type={type ?? 'text'}
            label={label ?? null}
            onChange={(e) => onChange(name, e.target.value)}
            error={errors[name] && errors[name].length > 0}
        />
        <Errors name={name} errors={errors}/>
    </>)
}
