import {useState} from "react";
import Errors from "./Errors";

export default function ImageInput(
    {
        label = null,
        name = null,
        onChange,
        errors,
        className = null
    }
) {
    const [preview, setPreview] = useState(null)
    const [valErrors, setErrors] = useState({})

    function validateFile(file) {
        if (file.size > 20000000) {
            setErrors({...valErrors, [name]: ['File size must be less than 20MB']})
        } else if (!file.type.includes('image')) {
            setErrors({...valErrors, [name]: ['File must be an image']})
        }

        if (valErrors.length > 0) {
            return false
        }
        return true
    }
    async function selectImage(e) {
        const file = e.target.files[0]

        if (!validateFile(file)) {
            return
        }

        setPreview(URL.createObjectURL(file))

        onChange(name, file)
    }

    return (<div className={className}>
        <span>{label}</span>
        <div className="flex w-full">
            <label
                className="w-3/4 flex justify-center h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
                <span className="flex items-center space-x-2">
                    <span className="font-medium text-gray-600">Drop files to Attach, or <span
                        className="text-blue-600 underline">browse</span></span></span>
                <input
                    onChange={(e) => {
                        selectImage(e)
                    }}
                    accept="image/*"
                    type="file"
                    className="hidden"
                />
            </label>
            <div className="pl-1 w-1/4">
                <div
                    className="bg-gray-200 rounded w-full h-full flex justify-center items-center">
                    {
                        preview
                            ? <img src={preview}
                                   alt={name}
                                   className="w-full"
                            />
                            : <span className="text-gray-400">No image selected</span>
                    }
                </div>
            </div>
        </div>
        <Errors name={name} errors={valErrors ?? errors}/>
    </div>)
}
