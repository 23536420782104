import PageView from "../../components/PageView";
import Modal from "../../components/Modal";
import {Button, Card} from "@material-tailwind/react";
import {useState} from "react";
import DefaultInput from "../../components/Form/DefaultInput";
import FormButtons from "../../components/Form/FormButtons";
import {validate} from "../../common/Form";
import {create, createMultiple, get, remove} from "../../common/Supabase";
import DefaultCellRenderer from "../../components/DataGrid/DefaultCellRenderer";
import DateCellRenderer from "../../components/DataGrid/DateCellRenderer";
import Spinner from "../../components/Spinner";
import ExpandCell from "../../components/DataGrid/ExpandCell";
import DataGrid from "../../components/DataGrid";
import SelectInput from "../../components/Form/SelectInput";
import DeleteCell from "../../components/DataGrid/DeleteCell";
import EditableColumns from "../../components/EditableColumns";
import DefaultEditCellRenderer from "../../components/DataGrid/EditCells/DefaultEditCellRenderer";
import SelectCellRenderer from "../../components/DataGrid/SelectCellRenderer";
import SelectEditCellRenderer from "../../components/DataGrid/EditCells/SelectEditCellRenderer";
import ChildDataGrid from "../../components/ChildDataGrid";
import {emitCustomEvent} from "react-custom-events";
import DefaultHeaderCellRendererWithFilters
    from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRendererWithFilters";
import SelectFilterHeaderCellRenderer from "../../components/DataGrid/HeaderCells/SelectFilterHeaderCellRenderer";
import DefaultHeaderCellRenderer from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRenderer";

export default function PurchaseOrder({session}) {
    const [showModal, setShowModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(true)
    const [showPurchaseOrderReceivedModal, setShowPurchaseOrderReceivedModal] = useState(false)
    const [purchaseOrderReceivedModalLoading, setPurchaseOrderReceivedModalLoading] = useState(true)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({})
    const [formType, setFormType] = useState('PurchaseOrder')
    const [supplierOptions, setSupplierOptions] = useState([])
    const [productOptions, setProductOptions] = useState([])
    const [brandOptions, setBrandOptions] = useState([])
    const [purchaseOrderLines, setPurchaseOrderLines] = useState([{
        product_id: null, brand_id: null, qty: null, received_record: null
    }])
    const [purchaseOrderLineErrors, setPurchaseOrderLineErrors] = useState([])
    const [purchaseOrderLineReceivedErrors, setPurchaseOrderLineReceivedErrors] = useState([])
    const [parentId, setParentId] = useState(null)

    function openModal(parentId = null, formType = 'PurchaseOrder') {
        setForm({})
        setParentId(parentId)
        setShowModal(true)
        setFormType(formType)

        if (formType === 'PurchaseOrder' || formType === 'PurchaseOrderLine') {
            setModalLoading(true)
            fetchAllOptions().then(() => setModalLoading(false))
        } else {
            setModalLoading(false)
        }
    }

    function openPurchaseOrderReceivedModal(parentId = null) {
        setParentId(parentId)
        setShowPurchaseOrderReceivedModal(true)
        setPurchaseOrderReceivedModalLoading(true)
    }

    async function fetchAllOptions(table, select) {
        await get('Products', 'id, product_code, product_name_hk, created_at')
            .then(data => setProductOptions(data))

        await get('Brand', 'id, brand_name, created_at')
            .then(data => setBrandOptions(data))

        if (formType === 'PurchaseOrder') {
            await get('Supplier', 'id, supplier_code, supplier_name, created_at')
                .then(data => setSupplierOptions(data))
        }
    }


    const table = 'PurchaseOrder'

    const childColumns = [
        {
            key: 'Products.product_code',
            name: 'Product Code',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
        },
        {
            key: 'Products.product_name_hk',
            name: 'Product Name',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
        },
        {
            key: 'Brand.brand_name',
            name: 'Brand Name',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
        },
        {
            key: 'qty',
            name: 'QTY',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
        },
        {
            key: 'received_record',
            name: 'Received Record',
            type: 'number',
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
        },
        {
            key: 'po_received',
            name: 'PO Received',
            // type: 'number',
            renderCell: ({row}) => {
                return <>
                    <Button
                        color="white"
                        className=""
                        onClick={() => {
                            openModal(row.id, 'PurchaseOrderLineReceived')
                        }}
                        type="button"
                    >Add</Button>
                    <Button
                        color="white"
                        className=""
                        onClick={() => {
                            openPurchaseOrderReceivedModal(row.id)
                        }}
                        type="button"
                    >Show</Button>
                </>;
            }
        },
        DeleteCell({
            onDelete: async (id, row) => {
                const {error} = await remove('PurchaseOrderLine', 'id', id)
                if (error) {
                    alert(error.details)
                }
                emitCustomEvent('refresh.ChildDataGrid.' + row.parentId)
            }
        })
    ];

    const columns = [
        {
            key: 'po_id',
            name: 'PO ID',
            type: 'text',
            sortable: true,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'order_date',
            name: 'Order Date',
            type: 'date',
            sortable: true,
            renderCell: DateCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'expected_arrival_date',
            name: 'Expected Arrival Date',
            type: 'date',
            sortable: true,
            renderCell: DateCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'order_status',
            name: 'Order Status',
            type: 'select',
            sortable: true,
            options: [{value: 'pending', label: 'Pending'}, {value: 'partial_received', label: 'Partial Received'}, {value: 'complete', label: 'Complete'}],
            renderCell: SelectCellRenderer,
            renderEditCell: SelectEditCellRenderer,
            renderHeaderCell: SelectFilterHeaderCellRenderer,
        },
    ];

    const rules = {
        po_id: {required: true, maxLength: 255, minLength: 3},
        order_date: {required: true},
        expected_arrival_date: {required: true},
        order_status: {required: true, in: ['pending', 'partial_received', 'complete']},
        supplier_id: {required: true},
    }

    const purchaseOrderLineRules = {
        product_id: {required: true},
        brand_id: {required: true},
        qty: {required: true, min: 1, max: 1000000},
        // received_record: {required: true},
    }

    const purchaseOrderLineReceivedRules = {
        received_date: {required: true},
        qty_received: {required: true, min: 1, max: 1000000},
    }

    const select = '*, Supplier(supplier_code, supplier_name)';

    const childSelect = 'id, purchase_order_id, qty, received_record, Products!PurchaseOrderLine_product_id_fkey(id, product_code, product_name_hk), Brand(brand_name)';

    async function submitPurchaseOrderLineReceived() {
        const errors = validate(form, purchaseOrderLineReceivedRules)
        setPurchaseOrderLineReceivedErrors(errors)

        if (Object.keys(errors).length > 0) {
            return
        }

        setModalLoading(true)

        const {data, error} =
            await create('PurchaseOrderLineReceived', {...form, purchase_orderline_id: parentId})

        if (error) {
            alert(error)
        }

        setModalLoading(false)
        setShowModal(false)

        emitCustomEvent('refresh.DataGrid.' + table)
    }
    async function submit() {
        if (formType === 'PurchaseOrderLineReceived') {
            await submitPurchaseOrderLineReceived()
            return
        }
        if (!parentId) {
            const errors = validate(form, rules)
            setErrors(errors)
        }

        let purchaseOrderLineErrorsNew = []
        for (let i = 0; i < purchaseOrderLines.length; i++) {
            const purchaseOrderLinesError = validate(purchaseOrderLines[i], purchaseOrderLineRules)

            if (Object.keys(purchaseOrderLinesError).length > 0) {
                purchaseOrderLineErrorsNew[i] = purchaseOrderLinesError
            }
        }
        setPurchaseOrderLineErrors(purchaseOrderLineErrorsNew)

        if ((!parentId && Object.keys(errors).length > 0) || purchaseOrderLineErrorsNew.length > 0) {
            return
        }

        setModalLoading(true)

        let purchase_order_id;

        if (!parentId) {
            const {data, error} = await create(table, form)
            if (error) {
                alert(error)
            }
            purchase_order_id = data.id
        } else {
            purchase_order_id = parentId
        }

        const {
            data: data2,
            error: error2
        } = await createMultiple('PurchaseOrderLine', purchaseOrderLines.map(purchaseOrderLine => {
            return {
                ...purchaseOrderLine, purchase_order_id: purchase_order_id
            }
        }))

        if (error2) {
            alert(error2)
        }

        setModalLoading(false)
        setShowModal(false)

        emitCustomEvent('refresh.DataGrid.' + table)
        // if (!parentId) {
        //     emitCustomEvent('refresh.DataGrid.' + table)
        // } else {
        //     emitCustomEvent('refresh.ChildDataGrid.' + parentId)
        // }
    }

    const gridColumns = [
        ExpandCell('PurchaseOrderLine', 'purchase_order_id', childSelect, childColumns),
        ...columns,
        {
            key: 'Supplier.supplier_code',
            name: 'Supplier Code',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderHeaderCell: DefaultHeaderCellRenderer,
        },
        {
            key: 'Supplier.supplier_name',
            name: 'Supplier Name',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderHeaderCell: DefaultHeaderCellRenderer,
        },
        {
            key: 'PurchaseOrderLine.Add',
            name: 'Purchase Order Line',
            renderHeaderCell: DefaultHeaderCellRenderer,
            renderCell({row, onRowChange}) {
                return <Button
                    color="white"
                    className="w-full"
                    onClick={() => {
                        openModal(row.id, 'PurchaseOrderLine')
                    }}
                    type="button"
                >Add</Button>;
            }
        },
        DeleteCell({
            onDelete: async (id) => {
                await remove('PurchaseOrderLine', 'purchase_order_id', id)
                const {error} = await remove(table, 'id', id)
                if (error) {
                    alert(error.details)
                }
                emitCustomEvent('refresh.DataGrid.' + table)
            },
            canClearFilters: true
        })
    ]

    function addPurchaseOrderLine() {
        setPurchaseOrderLines([...purchaseOrderLines, {
            product_id: null,
            brand_id: null,
            qty: null,
            // received_record: null,
        }])
    }

    function setPurchaseOrderLine(index, name, value) {
        setPurchaseOrderLines(purchaseOrderLines.map((purchaseOrderLine, i) => {
            if (i === index) {
                return {
                    ...purchaseOrderLine, [name]: value
                }
            }
            return purchaseOrderLine
        }))
    }

    return (<PageView>
        <Modal
            loading={modalLoading}
            onClose={() => setShowModal(false)}
            show={showModal}
            className={'bg-white px-6 py-10'}
            confirmText={'Submit'}
            header={formType === 'PurchaseOrder' ? 'Add Purchase Order' : formType === 'PurchaseOrderLine' ? 'Add Purchase Order Line' : 'PO Received'}
        >
            <Card color="transparent" shadow={false}>
                <form className="w-full">
                    <div className="mb-4 flex flex-col gap-6">
                        {
                            formType === 'PurchaseOrder' || formType === 'PurchaseOrderLine'
                                ? <>
                                    <DefaultInput
                                        label={columns[0].name}
                                        name={columns[0].key}
                                        rules={columns[0].rules}
                                        onChange={(name, value) => setForm({...form, [name]: value})}
                                        errors={errors}
                                    />
                                    <DefaultInput
                                        type={'date'}
                                        label={columns[1].name}
                                        name={columns[1].key}
                                        rules={columns[1].rules}
                                        onChange={(name, value) => setForm({...form, [name]: value})}
                                        errors={errors}
                                    />
                                    <DefaultInput
                                        type={'date'}
                                        label={columns[2].name}
                                        name={columns[2].key}
                                        rules={columns[2].rules}
                                        onChange={(name, value) => setForm({...form, [name]: value})}
                                        errors={errors}
                                    />
                                    <SelectInput
                                        label={columns[3].name}
                                        name={columns[3].key}
                                        rules={columns[3].rules}
                                        onChange={(name, value) => setForm({...form, [name]: value})}
                                        errors={errors}
                                        options={columns[3].options}
                                    />
                                    <SelectInput
                                        name={'supplier_id'}
                                        label={'Supplier'}
                                        table={'Supplier'}
                                        rules={{required: true}}
                                        onChange={(name, value) => setForm({...form, [name]: value})}
                                        errors={errors}
                                        options={supplierOptions}
                                        labelKeys={['supplier_code', 'supplier_name', 'created_at']}
                                    />
                                </> : null}
                        {
                            formType === 'PurchaseOrder' || formType === 'PurchaseOrderLine'
                                ? <>
                                    {
                                        modalLoading
                                            ? <span className="flex justify-center"><Spinner/></span>
                                            : <div className="border rounded p-2">
                                                Purchase Order Lines
                                                {purchaseOrderLines.map((purchaseOrderLine, index) => <div key={index}
                                                                                                           className="mb-2 rounded p-2 border">
                                                    {index + 1}
                                                    <span className="p-1">
                                                <DefaultInput
                                                    type={'number'}
                                                    label={'QTY'}
                                                    name={'qty'}
                                                    onChange={(name, value) => setPurchaseOrderLine(index, name, value)}
                                                    errors={purchaseOrderLineErrors[index] ? purchaseOrderLineErrors[index] : []}
                                                />
                                            </span>
                                            {/*        <span className="p-1">*/}
                                            {/*    <DefaultInput*/}
                                            {/*        type={'number'}*/}
                                            {/*        label={'Received Record'}*/}
                                            {/*        name={'received_record'}*/}
                                            {/*        onChange={(name, value) => setPurchaseOrderLine(index, name, value)}*/}
                                            {/*        errors={purchaseOrderLineErrors[index] ? purchaseOrderLineErrors[index] : []}*/}
                                            {/*        className="mb-2"*/}
                                            {/*    />*/}
                                            {/*</span>*/}
                                                    <span className="p-1">
                                                <SelectInput
                                                    name={'product_id'}
                                                    label={'Product'}
                                                    table={'Products'}
                                                    rules={{required: true}}
                                                    onChange={(name, value) => setPurchaseOrderLine(index, name, value)}
                                                    errors={purchaseOrderLineErrors[index] ? purchaseOrderLineErrors[index] : []}
                                                    options={productOptions}
                                                    labelKeys={['product_code', 'product_name_hk', 'created_at']}
                                                />
                                            </span>
                                                    <span className="p-1">
                                                <SelectInput
                                                    name={'brand_id'}
                                                    label={'Brand'}
                                                    table={'Brand'}
                                                    rules={{required: true}}
                                                    onChange={(name, value) => setPurchaseOrderLine(index, name, value)}
                                                    errors={purchaseOrderLineErrors[index] ? purchaseOrderLineErrors[index] : []}
                                                    options={brandOptions}
                                                    labelKeys={['brand_name', 'created_at']}
                                                />
                                            </span>
                                                </div>)}
                                                <Button onClick={addPurchaseOrderLine} color="amber"
                                                        className="my-2 w-full">Add</Button>
                                            </div>
                                    }
                                </>
                                : <>
                                    <DefaultInput
                                        type={'number'}
                                        label={'QTY RECEIVED'}
                                        name={'qty_received'}
                                        onChange={(name, value) => setForm({...form, [name]: value})}
                                        errors={purchaseOrderLineReceivedErrors}
                                    />
                                    <DefaultInput
                                        type={'date'}
                                        label={'RECEIVED DATE'}
                                        name={'received_date'}
                                        onChange={(name, value) => setForm({...form, [name]: value})}
                                        errors={purchaseOrderLineReceivedErrors}
                                    />
                                </>
                        }
                        <FormButtons
                            onSubmit={submit}
                            onClose={() => setShowModal(false)}
                            loading={modalLoading}
                        />
                    </div>
                </form>
            </Card>
        </Modal>
        <Modal
            loading={purchaseOrderReceivedModalLoading}
            show={showPurchaseOrderReceivedModal}
            className={'bg-white px-6 py-10'}
            confirmText={'Submit'}
            header={'PO Received'}
            onClose={() => setShowPurchaseOrderReceivedModal(false)}
        >
            <ChildDataGrid
                table={'PurchaseOrderLineReceived'}
                parentId={parentId}
                parentKey={'purchase_orderline_id'}
                columns={[
                    {
                        key: 'qty_received',
                        name: 'QTY Received',
                        type: 'number',
                        renderHeaderCell: ({column, ...q}) => DefaultHeaderCellRendererWithFilters({column, ...q}, 'ChildDataGrid'),
                    },
                    {
                        key: 'received_date',
                        name: 'Received Date',
                        type: 'date',
                        renderHeaderCell: ({column, ...q}) => DefaultHeaderCellRendererWithFilters({column, ...q}, 'ChildDataGrid'),
                    },
                    DeleteCell({
                        onDelete: async (id, row) => {
                            const {error} = await remove('PurchaseOrderLineReceived', 'id', id)
                            if (error) {
                                alert(error.details)
                            }
                            emitCustomEvent('refresh.ChildDataGrid.' + row.parentId)
                            emitCustomEvent('refresh.DataGrid.' + table)
                        }
                    })
                ]}
            />
            <FormButtons
                onSubmit={() => {
                    setShowPurchaseOrderReceivedModal(false)
                    openModal(parentId, 'PurchaseOrderLineReceived')
                }}
                onClose={() => setShowPurchaseOrderReceivedModal(false)}
                loading={false}
                submitText={'Add'}
            />
        </Modal>
        <Button onClick={() => {
            openModal()
        }} color="amber" className="my-2 ml-2">Add</Button>
        <EditableColumns columns={columns.filter((c) => c.key !== 'received_record')} table={table}/>
        <DataGrid
            table={table}
            columns={gridColumns}
            select={select}
        />
    </PageView>)
}
