import {create} from "./Supabase";

export function validate(data, rules) {
    const errors = {}
    Object.keys(rules).forEach(key => {
        const value = data[key]
        const rule = rules[key]
        errors[key] = []
        if (rule.required && (!value || (Array.isArray(value) && value.length === 0) || (typeof value === 'string' && value.trim() === ''))) {
            errors[key].push(`${key} is required`)
        }
        if (rule.maxLength && value?.length > rule.maxLength) {
            errors[key].push(`${key} must be less than ${rule.maxLength} characters`)
        }
        if (rule.minLength && value?.length < rule.minLength) {
            errors[key].push(`${key} must be more than ${rule.minLength} characters`)
        }
        if (rule.pattern && !rule.pattern.test(value)) {
            errors[key].push(`${key} is invalid`)
        }
        if (rule.min && value < rule.min) {
            errors[key].push(`${key} must be more than ${rule.min}`)
        }
        if (rule.max && value > rule.max) {
            errors[key].push(`${key} must be less than ${rule.max}`)
        }
        if (rule.email && !rule.email.test(value)) {
            errors[key].push(`${key} is invalid`)
        }
        if (rule.in && !rule.in.includes(value)) {
            errors[key].push(`${key} is invalid`)
        }

        if (errors[key].length === 0) {
            delete errors[key]
        }
    })
    return errors
}

export function submit(table, data, rules, setErrors, setModalLoading, setShowModal, finished) {
    const errors = validate(data, rules)
    setErrors(errors)

    if (
        (Array.isArray(errors) && errors.length > 0)
        || Object.keys(errors).length > 0
    ) {
        return
    }

    setModalLoading(true)

    create(table, data)
        .then(({data, error}) => {
            if (error) {
                alert(error)
                setErrors(error.message)
            } else {
                setShowModal(false)
            }
            setModalLoading(false)
            finished()
        })
}
