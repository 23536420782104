import {useState, useEffect} from 'react'
import {supabase} from '../supabaseClient'
import DefaultInput from "../components/Form/DefaultInput";
import PageView from "../components/PageView";

export default function Account({session}) {
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [profile, setProfile] = useState(null)

    useEffect(() => {
        getProfile()
    }, [session])

    async function getProfile() {
        try {
            setLoading(true)
            const {data: account} = await supabase.auth.getUser()

            setUser(account.user)

            let {data, error} = await supabase
                .from('profiles')
                .select(`username, website, avatar_url, role`)
                .eq('id', account.user.id)
                .single()

            setProfile(data)

            if (error) {
                alert(error.message)
            }

        } catch (error) {
            alert(error.message)
        } finally {
            setLoading(false)
        }
    }

    // async function updateProfile({username, website, avatar_url}) {
    //     try {
    //         setLoading(true)
    //         const user = supabase.auth.getUser()
    //
    //         const updates = {
    //             id: user.id,
    //             username,
    //             website,
    //             avatar_url,
    //             updated_at: new Date(),
    //         }
    //
    //         let {error} = await supabase.from('profiles').upsert(updates, {
    //             returning: 'minimal', // Don't return the value after inserting
    //         })
    //
    //         if (error) {
    //             throw error
    //         }
    //     } catch (error) {
    //         alert(error.message)
    //     } finally {
    //         setLoading(false)
    //     }
    // }

    return (
        <PageView className="bg-transparent border-0">
          <div className="p-5">
            <h2 className="mb-2">
              Account
            </h2>
            <div>
              {
                user
                    ? <>
                      <label>
                        Email
                        <DefaultInput
                            disabled={true}
                            label={'Email'}
                            defaultValue={user?.email}
                            errors={[]}
                        />
                      </label>
                      <label>
                        Role
                        <DefaultInput
                            className="bg-green-500"
                            disabled={true}
                            label={'Role'}
                            defaultValue={profile?.role?.toUpperCase()}
                            errors={[]}
                        />
                      </label>

                    </>
                    : null
              }
            </div>
          </div>
        </PageView>
    )
}
