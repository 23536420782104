import Errors from "./Errors";
import Select from 'react-select';

export default function SelectInput(
    {
        label,
        name,
        defaultValue,
        options = [],
        multiple = false,
        labelKeys = null,
        onChange,
        errors,
        className = null
    }
) {
    function change(e) {
        if (e) {
            if (multiple) {
                onChange(name, e.map((item) => item.value));
            } else {
                onChange(name, e.value);
            }
        }
    }

    return (<div className={className}>
        <div className="relative">
            <Select
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: null,
                        padding: 3
                    }),
                }}
                className="peer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm p-0 rounded-md border-blue-gray-200 focus:border-gray-900"
                name={name}
                defaultValue={defaultValue}
                isClearable
                isSearchable
                onChange={change}
                options={options.map((option, index) => {
                        const label = labelKeys ? labelKeys.map((labelKey, index) => {
                            return option[labelKey];
                        }) : option.label;
                        return {
                            value: (option.id ?? option.value) + '',
                            label: label
                        }
                    }
                )}
                isMulti={multiple}
            />
            <span
                className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[4.1] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900"
            >
                {label}
            </span>
        </div>

        {/*{*/}
        {/*    multiple*/}
        {/*        ? <Card>*/}
        {/*            <span className="border-b p-4">{label}</span>*/}
        {/*            <div className="max-h-96 overflow-scroll">*/}
        {/*                {options.map((option, index) => <Checkbox*/}
        {/*                    key={index}*/}
        {/*                    onChange={(e) => selectOption(option.id ?? option.value)}*/}
        {/*                    label={labelKeys.map((labelKey, index) => {*/}
        {/*                        return (<span key={index}>{option[labelKey]}</span>)*/}
        {/*                    })}*/}
        {/*                />)}*/}
        {/*            </div>*/}
        {/*        </Card>*/}
        {/*        : <Select*/}
        {/*            error={errors[name] && errors[name].length > 0}*/}
        {/*            name={name}*/}
        {/*            label={label}*/}
        {/*            defaultValue={defaultValue}*/}
        {/*            onChange={(e) => selectOption(e)}*/}
        {/*        >*/}
        {/*            {*/}
        {/*                options.map((option, index) => {*/}
        {/*                    const label = labelKeys ? labelKeys.map((labelKey, index) => {*/}
        {/*                        return (<span key={index}>{option[labelKey]}</span>)*/}
        {/*                    }) : option.label;*/}
        {/*                return <SelectOption*/}
        {/*                    key={index}*/}
        {/*                    value={(option.id ?? option.value) + ''}*/}
        {/*                    children={label}*/}
        {/*                />*/}
        {/*            })*/}
        {/*            }*/}
        {/*        </Select>*/}
        {/*}*/}
        <Errors name={name} errors={errors}/>
    </div>)
}
