import Spinner from "./Spinner";

const Loading = () => {

    return (
        <div
            className="absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center"
            style={{zIndex: 1000}}
        >
            <Spinner/>
        </div>
    )
}

export default Loading;
