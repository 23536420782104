import {supabase} from "../supabaseClient";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "@material-tailwind/react";

const EditableColumns = ({table, columns}) => {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const [editables, setEditables] = useState([])
    const isAdmin = useSelector((state) => state.auth.isAdmin ?? false);

    useEffect(() => {
        getEditableColumns().then(() => setLoading(false))
    }, []);

    async function getEditableColumns() {
        const {data, error} = await supabase
            .from('editable_columns')
            .select('*')
            .eq('table', table)

        setEditables(data)

        dispatch({type: 'SET_EDITABLE_COLUMNS', payload: data})
    }

    async function onChange(key, checked) {
        setLoading(true)
        let {data, error} = await supabase
            .from('editable_columns')
            .select()
            .eq('column', key)
            .eq('table', table)

        if (data.length > 0) {
            let {error} = await supabase
                .from('editable_columns')
                .update({editable: checked})
                .eq('id', data[0].id)
        } else {
            let {error} = await supabase
                .from('editable_columns')
                .insert({
                    table,
                    column: key,
                    editable: checked
                })
        }
        setLoading(false)

        setEditables(editables => {
            let newEditables = editables
            const editableIndex = editables.findIndex((editable) => editable.column === key)
            if (editableIndex !== -1) {
                newEditables[editableIndex].editable = checked
            } else {
                newEditables.push({
                    table,
                    column: key,
                    editable: checked
                })
            }
            return newEditables
        })
    }

    if (!isAdmin) return

    return (<div className="p-2">
        Editable Columns:
        <div className="pt-2 flex flex-wrap overflow-scroll min-w-full">
            {columns
                .filter((column) => column.key !== 'id' && column.key !== 'created_at' && column.key !== 'updated_at' && column.key !== 'expand' && column.key !== 'delete')
                .map((column) => <Checkbox
                        disabled={loading}
                        key={column.key}
                        label={column.name}
                        checked={editables.find((editable) => editable.column === column.key && editable.editable) ?? false}
                        onChange={(e) => onChange(column.key, e.target.checked)}
                    />
                    // <label
                    //     key={column.key}
                    //     className="pr-2 disabled:opacity-50 disabled:text-opacity-50"
                    // >
                    //     <span>{column.name}</span>
                    //     <input
                    //         className="pl-1"
                    //         onChange={(e) => onChange(column.key, e.target.checked)}
                    //         type="checkbox"
                    //         name={column.key}
                    //         checked={editables.find((editable) => editable.column === column.key && editable.editable) !== undefined}
                    //         disabled={loading}
                    //     />
                    // </label>
                )
            }
        </div>
    </div>)
}

export default EditableColumns
