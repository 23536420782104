const PageView = ({children, background = 'bg-white shadow rounded-lg'}) => {
    return (
        <div className="pt-2 lg:pt-6 lg:px-4 w-full lg:w-5/6 lg:fixed right-0">
            <div className={"w-full h-full lg:mt-16 2xl:col-span-2 " + background}>
                {children}
            </div>
        </div>
    );
}

export default PageView;
