import {useState} from "react";
import {supabase} from "../../../supabaseClient";
import {emitCustomEvent} from "react-custom-events";

export default function ImageEditCellRenderer({column, row}) {
    const [loading, setLoading] = useState(false)
    const [uploaded, setUploaded] = useState(false)

    async function handleUpload(event) {
        setLoading(true)
        const file = event.target.files[0]

        const extension = file.name.split('.').pop()
        let name = file.name.split('.')[0].replace(/[^a-zA-Z0-9]/g, '_')

        name = name + '_' + Date.now()

        const {data, error} = await supabase
            .storage
            .from('mb_images')
            .upload('Tables/' + column.table + '/' + name + '.' + extension, file, {
                cacheControl: '3600',
                upsert: false
            })

        const res = await supabase
            .storage
            .from('mb_images')
            .getPublicUrl(data.path)

        const imageUrl = res.data.publicUrl.replace(process.env.REACT_APP_SUPABASE_URL, '')

        emitCustomEvent('DataGrid.RowUpdated', {
            id: row.id,
            key: column.key,
            value: imageUrl,
        })

        setLoading(false)
        setUploaded(true)
    }

    return <label className="w-full h-full">
        {
            loading ? 'Uploading...' : uploaded ? 'Uploaded, please un focus' : <input
                autoFocus
                disabled={loading}
                className="w-full"
                type='file'
                accept='image/*'
                onChange={handleUpload}
            />
        }
    </label>
}
