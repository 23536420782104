export default function EmptyRowsRenderer() {
    return (
        <div className="mt-5" style={{textAlign: 'center', gridColumn: '1/-1'}}>
            Nothing to show{' '}
            <span lang="ja" title="ショボーン">
        (´・ω・`)
      </span>
        </div>
    );
}
