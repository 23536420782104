import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {supabase} from "../supabaseClient";
import Spinner from "./Spinner";

const Header = () => {
    const [loading, setLoading] = useState(false)

    function logout() {
        setLoading(true)
        supabase
            .auth
            .signOut()
            .then(() => {
                setLoading(false)
                window.location.reload()
            })
    }

    return (
        // <div className="flex justify-between w-full p-2 border-b">
        //     <div>
        //         MB
        //     </div>
        //     <div className="text-blue-500">
        //         <Link to={"/account"} className="mr-2">Account</Link>
        //         <button>
        //             Logout
        //         </button>
        //     </div>
        // </div>
        <nav className="bg-white border-b border-gray-200 fixed z-30 w-full">
            <div className="px-3 py-3 lg:px-5 lg:pl-3">
                <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start">
                        {/*<button id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar" className="lg:hidden mr-2 text-gray-600 hover:text-gray-900 cursor-pointer p-2 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100 rounded">*/}
                        {/*    <svg id="toggleSidebarMobileHamburger" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>*/}
                        {/*    </svg>*/}
                        {/*    <svg id="toggleSidebarMobileClose" className="w-6 h-6 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>*/}
                        {/*    </svg>*/}
                        {/*</button>*/}
                        <Link to={"/"}
                              className="text-xl font-normal flex items-center lg:ml-2.5 border-b-2 border-t-2 p-2 border-gray-500">
                            {/*<img src="https://demo.themesberg.com/windster/images/logo.svg" className="h-6 mr-2" alt="Windster Logo"/>*/}
                            <span className="self-center whitespace-nowrap">MB</span>
                        </Link>
                        {/*<form action="#" method="GET" className="hidden lg:block lg:pl-32">*/}
                        {/*    <label htmlFor="topbar-search" className="sr-only">Search</label>*/}
                        {/*    <div className="mt-1 relative lg:w-64">*/}
                        {/*        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">*/}
                        {/*            <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>*/}
                        {/*            </svg>*/}
                        {/*        </div>*/}
                        {/*        <input type="text" name="email" id="topbar-search" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full pl-10 p-2.5" placeholder="Search"/>*/}
                        {/*    </div>*/}
                        {/*</form>*/}
                    </div>
                    <div className="flex items-center">
                        {/*<button id="toggleSidebarMobileSearch" type="button" className="lg:hidden text-gray-500 hover:text-gray-900 hover:bg-gray-100 p-2 rounded-lg">*/}
                        {/*    <span className="sr-only">Search</span>*/}
                        {/*    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                        {/*        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>*/}
                        {/*    </svg>*/}
                        {/*</button>*/}
                        {/*<div className="hidden lg:flex items-center">*/}
                        {/*    <span className="text-base font-normal text-gray-500 mr-5">Open source ❤️</span>*/}
                        {/*    <div className="-mb-1">*/}
                        {/*        <span></span>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <Link
                            to={"/account"}
                            className="hidden sm:inline-flex ml-5 focus:ring-4 focus:ring-cyan-200 rounded-lg px-2 py-2.5 text-center items-center"
                        >
                            <svg
                                className="w-6 h-6 text-gray-500 flex-shrink-0 group-hover:text-gray-900 transition duration-75"
                                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                      clipRule="evenodd"></path>
                            </svg>
                        </Link>
                        {
                            loading
                                ? <Spinner/>
                                : <button
                                    onClick={logout}
                                    className="hidden sm:inline-flex text-white focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center items-center"
                                >
                                    <svg
                                        className="w-6 h-6 text-gray-500 flex-shrink-0 group-hover:text-gray-900 transition duration-75"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                                              clipRule="evenodd"></path>
                                    </svg>
                                </button>
                        }
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Header;
