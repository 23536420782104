export default function Errors({name, errors})
{
    return (<div className="text-red-500 text-sm">
        {
            errors[name]?.map((error, index) => {
                    return (
                        <div key={index}>{error}</div>
                    )
                }
            )
        }
    </div>)
}
