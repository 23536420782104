import Spinner from "./Spinner";
import {default as DataGridOriginal} from "react-data-grid";
import {useEffect, useState} from "react";
import {supabase} from "../supabaseClient";
import Pagination from "./Pagination";
import EmptyRowsRenderer from "./DataGrid/EmptyRowsRenderer";
import {getPagination} from "../common/DataGrid/Pagination";
import {useCustomEventListener} from 'react-custom-events'

export default function DataGrid({table, select, columns = [], rowHeight = 45}) {
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [total, setTotal] = useState(null)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(50)
    const [order, setOrder] = useState([{
        columnKey: 'id',
        direction: 'ASC',
    }])
    const [filters, setFilters] = useState({})
    const [direction, setDirection] = useState('ASC')
    const [syncing, setSyncing] = useState(false)
    const [tableKey, setTableKey] = useState(Math.random())

    useCustomEventListener('refresh.DataGrid.' + table, (data) => {
        fetch(page)
    })

    useCustomEventListener('DataGrid.RowUpdated', (data) => {
            setSyncing(true)

            setRows(rows => {
                const rowIndex = rows.findIndex((r) => r.id === data.id)
                let newRowsData = rows
                newRowsData[rowIndex][data.key] = data.value
                return newRowsData
            })

            syncWithSupabase(data.id, data.key, data.value)
                .then(() => {
                    setSyncing(false)
                })
        }
    )

    useCustomEventListener('DataGrid.FilterUpdated', (data) => {
        setFilters(prevState => ({
            ...prevState,
            [data.key]: data.value
        }))
        fetch(
            1,
            {
                column: 'id',
                direction: 'ASC',
            },
            {
                ...filters,
                [data.key]: data.value
            }
        )
    })

    useCustomEventListener('DataGrid.ClearFilters', () => {
        setFilters({})
        fetch(
            1,
            {
                column: 'id',
                direction: 'ASC',
            },
            {}
        )
    })

    useCustomEventListener('DataGrid.Order', (data) => {
        onSortColumnsChange([
            {
                columnKey: data.key,
                direction: data.direction,
            }
        ])
    })

    useEffect(() => {
        fetch()

        // subscribe('refresh.DataGrid.' + table, () => {
        //     fetch(page)
        // })
        //
        // subscribe('DataGrid.RowUpdated', (event) => {
        //     setSyncing(true)
        //
        //     setRows(rows => {
        //         const rowIndex = rows.findIndex((r) => r.id === event.detail.id)
        //         let newRowsData = rows
        //         newRowsData[rowIndex][event.detail.key] = event.detail.value
        //         return newRowsData
        //     })
        //
        //     syncWithSupabase(event.detail.id, event.detail.key, event.detail.value)
        //         .then(() => {
        //             setSyncing(false)
        //         })
        // })

        // const channel = supabase
        //     .channel('schema-db-changes')
        //     .on(
        //         'postgres_changes',
        //         {
        //             event: 'UPDATE',
        //             schema: 'public',
        //         },
        //         (payload) => {
        //             if (payload.table === table) {
        //                 setRows(rows => {
        //                     const rowIndex = rows.findIndex((r) => r.id === payload.new.id)
        //                     let newRowsData = rows
        //
        //                     for (const [key] of Object.entries(newRowsData[rowIndex])) {
        //                         if (payload.new[key]) {
        //                             newRowsData[rowIndex][key] = payload.new[key]
        //                         }
        //                     }
        //                     return newRowsData
        //                 })
        //             }
        //             forceUpdate()
        //         }
        //     )
        //     .subscribe()

        // return () => {
        //     unsubscribe('refresh.DataGrid.' + table)
        //     unsubscribe('DataGrid.RowUpdated')
        //     // channel.unsubscribe()
        // }
    }, []);

    // function forceUpdate() {
    //     setTableKey(Math.random() * 100)
    // }

    async function fetch(page = 1, sort = {
        column: 'id',
        direction: 'ASC',
    }, filters = {}) {
        setLoading(true)

        const {from, to} = getPagination(page, limit)

        const query = supabase
            .from(table)
            .select(select, {count: 'exact', head: false})
            .order(sort.column, {ascending: sort.direction === 'ASC'})
            .limit(limit)
            .range(from, to)

        if (Object.keys(filters).length > 0) {
            Object.entries(filters).forEach((filter) => {
                if (filter[1] === 'None' || filter[1] === '' || filter[1] === null) {
                    return
                }
                if (filter[1].includes('-')) {
                    const date1 = new Date(filter[1]);
                    const date2 = new Date();

                    date2.setDate(date1.getDate() + 1)

                    const dateString1 = date1.toISOString();
                    const dateString2 = date2.toISOString();

                    query.lt(filter[0], dateString2)
                    query.gt(filter[0], dateString1)
                } else if (!isNaN(parseInt(filter[1]))) {
                    query.filter(filter[0], 'eq', filter[1])
                } else {
                    query.filter(filter[0], 'like', '%' + filter[1] + '%')
                }
            })
        }

        let {data, error, count} = await query

        if (error) {
            throw error
        }

        setTotal(count)

        setRows([])

        setRows(data.map(r => {
            r.expanded = false
            return r
        }))

        setLoading(false)
    }

    //Pagination
    const onNextPage = (page) => {
        fetch(page);
    }
    const onPrevPage = (page) => {
        fetch(page);
    }
    const onSetPage = (page) => {
        fetch(page);
    }
    const onSetPerPage = (limit) => {
        setLimit(limit);
        setPage(1)
        fetch(1);
    }

    async function syncWithSupabase(id, key, value) {
        await supabase
            .from(table)
            .update({[key]: value})
            .eq('id', id)
    }

    function onRowsChange(rows, {indexes}) {
        const row = rows[indexes[0]];

        if (row.expanded) {
            rows.splice(indexes[0] + 1, 0, {
                type: 'DETAIL',
                id: row.id + 100,
                parentId: row.id
            });
        } else {
            rows.splice(indexes[0] + 1, 1);
        }
        setRows(rows);
    }

    function onSortColumnsChange(sortColumns) {
        if (sortColumns.length !== 0) {
            const newDirection = direction === 'ASC' ? 'DESC' : 'ASC'
            setDirection(direction === 'ASC' ? 'DESC' : 'ASC');
            setOrder(sortColumns.slice(-1));
            fetch(page, {
                column: sortColumns.slice(-1)[0].columnKey,
                direction: newDirection,
            })
        }
    }

    return (<div className="p-2 relative">
        <div className="flex flex-col justify-between h-full">
            <div>
                <DataGridOriginal
                    sortColumns={order}
                    key={tableKey}
                    onRowsChange={onRowsChange}
                    columns={columns}
                    rows={rows}
                    className="w-full h-full"
                    headerRowHeight={80}
                    rowHeight={(row) => (row.type === 'DETAIL' ? 300 : rowHeight)}
                    renderers={
                        {
                            noRowsFallback: <EmptyRowsRenderer/>,
                            renderSortStatus: (props) => {
                                if (props.sortDirection !== 'undefined') {
                                    return (<div className="pt-4">
                                        <div
                                            className={`h-0 w-0 border-x-8 border-x-transparent border-b-[16px] border-b-black transform ${props.sortDirection === 'ASC' ? '' : 'rotate-180'}`}></div>
                                    </div>)
                                }
                            }
                        }
                    }
                />
            </div>
            {
                loading
                    ? <div className="text-red-400 flex justify-center pt-10">
                        <Spinner/>
                    </div>
                    : ''
            }
            <Pagination
                className="mt-5"
                total={total}
                limit={limit}
                onNextPage={onNextPage}
                onPrevPage={onPrevPage}
                onSetPage={(page) => onSetPage(page)}
                onSetPerPage={(page) => onSetPerPage(page)}
            />

            {
                syncing
                    ? <div className="absolute -top-5 right-2">
                        <Spinner/>
                    </div>
                    : null
            }
        </div>
    </div>)
}
