import {useState} from 'react'
import {supabase} from '../supabaseClient'
import {Button, Card, CardBody, Input} from "@material-tailwind/react";
import Errors from "../components/Form/Errors";

export default function Auth() {
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const [email, setEmail] = useState('')
    const [errors, setErrors] = useState({})

    const handleLogin = async (email) => {
        if (!email) return setErrors({email: ['Email is required']})

        try {
            setLoading(true)
            const res = await supabase.auth.signInWithOtp({
                email,
                options: {
                    emailRedirectTo: window.location.origin
                }
            },)
            if (res.error) throw res.error
            setSent(true)
        } catch (error) {
            setSent(false)
            alert(error.error_description || error.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex justify-center items-center w-full h-screen">
            <Card className="p-2">
                {
                    sent
                        ? <CardBody className="text-green-500">Email with password has been sent!</CardBody>
                        : <CardBody className="rounded">
                            <div>
                                <h1 className="text-xl text-center">Login</h1>
                                <p className="text-center mb-2">Sign in via magic link with your email below</p>
                            </div>
                            <form onSubmit={(e) => {
                                e.preventDefault()
                                handleLogin(email)
                            }}>
                                <div>
                                    <Input
                                        className="mb-2"
                                        type="email"
                                        placeholder="Your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Errors errors={errors} name={'email'}/>
                                </div>
                                <div>
                                    <Button
                                        color='blue'
                                        onClick={(e) => {
                                            e.preventDefault()
                                            handleLogin(email)
                                        }}
                                        className={'mt-2 w-full'}
                                        disabled={loading}
                                    >
                                        {loading ? <span>Loading</span> : <span>Send magic link</span>}
                                    </Button>
                                </div>
                            </form>
                        </CardBody>
                }
            </Card>
        </div>
    )
}
