import {useEffect, useRef, useState} from "react";
import {
    Dialog,
    DialogHeader,
    DialogBody,
} from "@material-tailwind/react";
import {AiOutlineClose} from "react-icons/ai";

export default function Modal({show = false, onClose, onConfirm, confirmText = 'Confirm', children, className = '', header = null, loading = false}) {
    const [open, setOpen] = useState(false);
    const modalRef = useRef();

    const handleOpen = () => setOpen(!open);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    if (!show) {
        return null;
    }

    return (
        <Dialog
            open={show}
            handler={handleOpen}
            className="max-h-screen overflow-scroll"
            ref={modalRef}
        >
            <DialogHeader className="relative">
                {
                    header
                }
                <button className="border-0 outline-0 font-thin text-xl absolute right-3"><AiOutlineClose onClick={onClose}/></button>
            </DialogHeader>
            <DialogBody
                divider
            >
                {children}
            </DialogBody>
        </Dialog>
)
}
