import PageView from "../../components/PageView";
import {Button, Card} from "@material-tailwind/react";
import EditableColumns from "../../components/EditableColumns";
import DataGrid from "../../components/DataGrid";
import {useState} from "react";
import DefaultCellRenderer from "../../components/DataGrid/DefaultCellRenderer";
import DefaultInput from "../../components/Form/DefaultInput";
import SelectInput from "../../components/Form/SelectInput";
import FormButtons from "../../components/Form/FormButtons";
import Modal from "../../components/Modal";
import DateCellRenderer from "../../components/DataGrid/DateCellRenderer";
import ExpandCell from "../../components/DataGrid/ExpandCell";
import DefaultEditCellRenderer from "../../components/DataGrid/EditCells/DefaultEditCellRenderer";
import SelectCellRenderer from "../../components/DataGrid/SelectCellRenderer";
import SelectEditCellRenderer from "../../components/DataGrid/EditCells/SelectEditCellRenderer";
import DeleteCell from "../../components/DataGrid/DeleteCell";
import {create, createMultiple, get, remove} from "../../common/Supabase";
import Spinner from "../../components/Spinner";
import {validate} from "../../common/Form";
import ChildDataGrid from "../../components/ChildDataGrid";
import {emitCustomEvent} from "react-custom-events";
import DefaultHeaderCellRendererWithFilters
    from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRendererWithFilters";
import SelectFilterHeaderCellRenderer from "../../components/DataGrid/HeaderCells/SelectFilterHeaderCellRenderer";
import DefaultHeaderCellRenderer from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRenderer";

export default function Orders() {
    const [showModal, setShowModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(true)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({})
    const [formType, setFormType] = useState('CustomerOrders')
    const [products, setProducts] = useState([
        {
            product_id: null, order_qty: null, shipped_qty: null, delivery_status: null
        }
    ])
    const [productsErrors, setProductsErrors] = useState([])
    const [productOptions, setProductOptions] = useState([])
    const [showCustomerOrderShippedModal, setShowCustomerOrderShippedModal] = useState(false)
    const [customerOrderShippedModalLoading, setCustomerOrderShippedModalLoading] = useState(true)
    const [customerOrderShippedModalErrors, setCustomerOrderShippedModalErrors] = useState([])
    const [parentId, setParentId] = useState(null)

    function openModal(parentId = null, formType = 'CustomerOrders') {
        setFormType(formType)
        setParentId(parentId)
        setShowModal(true)

        if (formType === 'CustomerOrders' || formType === 'CustomerOrderProducts') {
            setModalLoading(true)
            fetchAllOptions().then(() => setModalLoading(false))
        } else {
            setModalLoading(false)
        }
    }

    function openCustomerOrderShipped(parentId = null) {
        setParentId(parentId)
        setShowCustomerOrderShippedModal(true)
        setCustomerOrderShippedModalLoading(true)
    }

    async function fetchAllOptions(table, select) {
        await get('Products', 'id, product_code, product_name_hk, created_at')
            .then(data => setProductOptions(data))
    }

    const table = 'CustomerOrders'

    const columns = [
        {
            key: 'customer_order_id',
            name: 'Order ID',
            type: 'text',
            sortable: true,
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'order_date',
            name: 'Order Date',
            type: 'date',
            sortable: true,
            renderCell: DateCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'order_total',
            name: 'Order Total',
            type: 'number',
            sortable: true,
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'order_delivery_status',
            name: 'Order Delivery Status',
            type: 'select',
            sortable: true,
            renderCell: SelectCellRenderer,
            renderEditCell: SelectEditCellRenderer,
            options: [
                {label: 'Pending', value: 'pending'},
                {label: 'Partial Delivered', value: 'partial_delivered'},
                {label: 'Complete', value: 'complete'},
            ],
            renderHeaderCell: SelectFilterHeaderCellRenderer,
        },
        {
            key: 'comment',
            name: 'Comment',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'Products.Add',
            name: 'Order Products',
            cellClass: 'p-0',
            renderHeaderCell: DefaultHeaderCellRenderer,
            renderCell({row, onRowChange}) {
                return <Button
                    color="white"
                    className="w-full m-0 rounded-0"
                    onClick={() => {
                        openModal(row.id, 'CustomerOrderProducts')
                    }}
                    type="button"
                >Add</Button>;
            }
        },
        DeleteCell({
            onDelete: async (id) => {
                await remove('CustomerOrderProducts', 'customer_order_id', id)
                const {error} = await remove(table, 'id', id)
                if (error) {
                    alert(error.details)
                }
                emitCustomEvent('refresh.DataGrid.' + table)
            },
            canClearFilters: true
        })
    ]

    const select = 'id, customer_order_id, order_date, order_total, order_delivery_status, comment'

    const gridColumns = [
        ExpandCell(
            'CustomerOrderProducts',
            'customer_order_id',
            'id, order_qty, shipped_qty, delivery_status, Products(product_code, product_name_hk, product_eng_name)',
            [
                {
                    key: 'order_qty',
                    name: 'Order Qty',
                    type: 'number',
                    renderCell: DefaultCellRenderer,
                },
                {
                    key: 'shipped_qty',
                    name: 'Shipped Qty',
                    type: 'date',
                    renderCell: DefaultCellRenderer,
                },
                {
                    key: 'delivery_status',
                    name: 'Delivery Status',
                    type: 'select',
                    renderCell: SelectCellRenderer,
                    renderEditCell: SelectEditCellRenderer,
                    defaultValue: 'pending',
                    options: [
                        {label: 'Pending', value: 'pending'},
                        {label: 'Partial Delivered', value: 'partial_delivered'},
                        {label: 'Complete', value: 'complete'},
                    ]
                },
                {
                    key: 'Products.product_code',
                    name: 'Product Code',
                    type: 'text',
                    renderCell: DefaultCellRenderer,
                },
                {
                    key: 'Products.product_name_hk',
                    name: 'Product Name (HK)',
                    type: 'text',
                    renderCell: DefaultCellRenderer,
                },
                {
                    key: 'Products.product_eng_name',
                    name: 'Product Name (EN)',
                    type: 'text',
                    renderCell: DefaultCellRenderer,
                },
                {
                    key: 'orders_shipped',
                    name: 'Order Shipped',
                    // type: 'number',
                    renderCell: ({row}) => {
                        return <>
                            <Button
                                color="white"
                                className=""
                                onClick={() => {
                                    openModal(row.id, 'CustomerOrderShipped')
                                }}
                                type="button"
                            >Add</Button>
                            <Button
                                color="white"
                                className=""
                                onClick={() => {
                                    openCustomerOrderShipped(row.id)
                                }}
                                type="button"
                            >Show</Button>
                        </>;
                    }
                },
                DeleteCell({
                    onDelete: async (id, row) => {
                        const {error} = await remove('CustomerOrderProducts', 'id', id)
                        if (error) {
                            alert(error.details)
                        }
                        emitCustomEvent('refresh.DataGrid.' + table)
                    }
                })
            ]
        ),
        ...columns,
    ]

    const rules = {
        customer_order_id: {required: true},
        order_date: {required: true},
        order_delivery_status: {required: true, in: ['pending', 'partial_delivered', 'complete']},
    }

    const productsRules = {
        product_id: {required: true},
        order_qty: {required: true, min: 0, max: 9999999999},
        shipped_qty: {required: true, min: 0, max: 9999999999},
        delivery_status: {required: true, in: ['pending', 'partial_delivered', 'complete']},
    }

    const customerOrderShippedRules = {
        shipped_qty: {required: true, min: 0, max: 9999999999},
        shipped_date: {required: true},
    }

    async function submitCustomerOrderShipped() {
        const errors = validate(form, customerOrderShippedRules)
        setCustomerOrderShippedModalErrors(errors)

        if (Object.keys(errors).length > 0) {
            console.error(errors)
            return
        }

        setModalLoading(true)

        const {data, error} =
            await create('CustomerOrderShipped', {...form, customer_order_product_id: parentId})

        if (error) {
            alert(error)
        }

        setModalLoading(false)
        setShowModal(false)

        emitCustomEvent('refresh.DataGrid.' + table)
    }

    async function submit() {
        if (formType === 'CustomerOrderShipped') {
            await submitCustomerOrderShipped()
            return
        }
        if (!parentId) {
            const errors = validate(form, rules)
            setErrors(errors)
        }
        //todo: bug order delivery status not showing errors
        let productsErrorsNew = []
        for (let i = 0; i < products.length; i++) {
            const purchaseOrderLinesError = validate(products[i], productsRules)

            if (Object.keys(purchaseOrderLinesError).length > 0) {
                productsErrorsNew[i] = purchaseOrderLinesError
            }
        }
        setProductsErrors(productsErrorsNew)

        if ((!parentId && Object.keys(errors).length > 0) || productsErrorsNew.length > 0) {
            console.error(errors, productsErrorsNew)
            return
        }

        setModalLoading(true)

        let customer_order_id;

        if (!parentId) {
            const {data, error} = await create(table, form)
            if (error) {
                alert(error)
            }
            customer_order_id = data.id
        } else {
            customer_order_id = parentId
        }

        const {
            data: data2,
            error: error2
        } = await createMultiple('CustomerOrderProducts', products.map(product => {
            return {
                ...product, customer_order_id: customer_order_id
            }
        }))

        if (error2) {
            alert(error2)
        }

        setModalLoading(false)
        setShowModal(false)

        emitCustomEvent('refresh.DataGrid.' + table)
    }

    function addProducts() {
        setProducts([...products, {
            product_id: null, order_qty: null, shipped_qty: null, delivery_status: null
        }])
    }

    function updateProducts(index, name, value) {
        setProducts(products.map((product, i) => {
            if (i === index) {
                return {
                    ...product, [name]: value
                }
            }
            return product
        }))
    }

    return (
        <PageView>
            <Modal
                loading={modalLoading}
                onClose={() => setShowModal(false)}
                show={showModal}
                className={'bg-white px-6 py-10'}
                confirmText={'Submit'}
                header={formType === 'CustomerOrders' ? 'Add Order' : 'Add Products To Order'}
            >
                <Card color="transparent" shadow={false}>
                    <form className="w-full">
                        <div className="mb-4 flex flex-col gap-6">
                            {
                                formType === 'CustomerOrders'
                                    ? <>
                                        <DefaultInput
                                            label={columns[0].name}
                                            name={columns[0].key}
                                            onChange={(name, value) => setForm({...form, [name]: value})}
                                            errors={errors}
                                        />
                                        <DefaultInput
                                            type={'date'}
                                            label={columns[1].name}
                                            name={columns[1].key}
                                            onChange={(name, value) => setForm({...form, [name]: value})}
                                            errors={errors}
                                        />
                                        <SelectInput
                                            name={columns[3].key}
                                            label={columns[3].name}
                                            onChange={(name, value) => setForm({...form, [name]: value})}
                                            errors={errors}
                                            options={columns[3].options}
                                        />
                                    </>
                                    : null
                            }
                            {
                                formType === 'CustomerOrderProducts'
                                    ? <>
                                        {
                                            modalLoading ? <span className="flex justify-center"><Spinner/></span> :
                                                <div className="border rounded p-2">
                                                    Products
                                                    {
                                                        products.map((purchaseOrderLine, index) =>
                                                            <div key={index}
                                                                 className="mb-2 rounded p-2 border">
                                                                {index + 1}
                                                                <span className="p-1">
                                                        <DefaultInput
                                                            type={'number'}
                                                            label={'Order Qty'}
                                                            name={'order_qty'}
                                                            onChange={(name, value) => updateProducts(index, name, value)}
                                                            errors={productsErrors[index] ? productsErrors[index] : []}
                                                        />
                                                    </span>
                                                                <span className="p-1">
                                                        <DefaultInput
                                                            type={'number'}
                                                            label={'Shipped Qty'}
                                                            name={'shipped_qty'}
                                                            onChange={(name, value) => updateProducts(index, name, value)}
                                                            errors={productsErrors[index] ? productsErrors[index] : []}
                                                        />
                                                    </span>
                                                                <span className="p-1">
                                                        <SelectInput
                                                            name={'delivery_status'}
                                                            label={'Delivery Status'}
                                                            onChange={(name, value) => updateProducts(index, name, value)}
                                                            errors={productsErrors[index] ? productsErrors[index] : []}
                                                            options={[
                                                                {label: 'Pending', value: 'pending'},
                                                                {
                                                                    label: 'Partial Delivered',
                                                                    value: 'partial_delivered'
                                                                },
                                                                {label: 'Complete', value: 'complete'},
                                                            ]}
                                                        />
                                                    </span>
                                                                <span className="p-1">
                                                        <SelectInput
                                                            name={'product_id'}
                                                            label={'Product'}
                                                            onChange={(name, value) => updateProducts(index, name, value)}
                                                            errors={productsErrors[index] ? productsErrors[index] : []}
                                                            options={productOptions}
                                                            labelKeys={['product_code', 'product_name_hk', 'created_at']}
                                                        />
                                                    </span>
                                                            </div>)}
                                                    <Button onClick={addProducts} color="amber"
                                                            className="my-2 w-full">Add</Button>
                                                </div>
                                        }
                                    </>
                                    : <>
                                        <DefaultInput
                                            label={'Shipped Qty'}
                                            name={'shipped_qty'}
                                            onChange={(name, value) => setForm({...form, [name]: value})}
                                            errors={customerOrderShippedModalErrors}
                                        />
                                        <DefaultInput
                                            type={'date'}
                                            label={'Shipped Date'}
                                            name={'shipped_date'}
                                            onChange={(name, value) => setForm({...form, [name]: value})}
                                            errors={customerOrderShippedModalErrors}
                                        />
                                        <DefaultInput
                                            label={'Waybill'}
                                            name={'shipped_waybill'}
                                            onChange={(name, value) => setForm({...form, [name]: value})}
                                            errors={customerOrderShippedModalErrors}
                                        />
                                    </>
                            }
                            <FormButtons
                                onSubmit={submit}
                                onClose={() => setShowModal(false)}
                                loading={modalLoading}
                            />
                        </div>
                    </form>
                </Card>
            </Modal>
            <Modal
                loading={customerOrderShippedModalLoading}
                show={showCustomerOrderShippedModal}
                className={'bg-white px-6 py-10'}
                confirmText={'Submit'}
                header={'Order Shipped'}
                onClose={() => setShowCustomerOrderShippedModal(false)}
            >
                <ChildDataGrid
                    table={'CustomerOrderShipped'}
                    parentId={parentId}
                    parentKey={'customer_order_product_id'}
                    columns={[
                        {
                            key: 'shipped_qty',
                            name: 'Shipped Qty',
                            type: 'number',
                            renderCell: DefaultCellRenderer,
                            renderHeaderCell: DefaultHeaderCellRenderer,
                        },
                        {
                            key: 'shipped_date',
                            name: 'Shipped Date',
                            type: 'date',
                            renderCell: DefaultCellRenderer,
                            renderHeaderCell: DefaultHeaderCellRenderer,
                        },
                        {
                            key: 'shipped_waybill',
                            name: 'Waybill',
                            type: 'text',
                            renderCell: DefaultCellRenderer,
                            renderHeaderCell: DefaultHeaderCellRenderer,
                        },
                        DeleteCell({
                            onDelete: async (id, row) => {
                                const {error} = await remove('CustomerOrderShipped', 'id', id)
                                if (error) {
                                    alert(error.details)
                                }
                                emitCustomEvent('refresh.ChildDataGrid.' + row.parentId)
                                emitCustomEvent('refresh.DataGrid.' + table)
                            }
                        })
                    ]}
                />
                <FormButtons
                    onSubmit={() => {
                        setShowCustomerOrderShippedModal(false)
                        openModal(parentId, 'CustomerOrderShipped')
                    }}
                    onClose={() => setShowCustomerOrderShippedModal(false)}
                    loading={false}
                    submitText={'Add'}
                />
            </Modal>
            <Button onClick={() => {
                openModal()
            }} color="amber" className="my-2 ml-2">Add</Button>
            <EditableColumns columns={columns.filter((column) => column.key !== 'order_total')} table={table}/>
            <DataGrid
                table={table}
                columns={gridColumns}
                select={select}
            />
        </PageView>
    )
}
