
export default function ImageCellRenderer({column, row})
{
    return row[column.key] ? <div className="relative">
        <img className="w-full" src={process.env.REACT_APP_SUPABASE_URL + row[column.key]} alt={column.key}/>
        <button
            onClick={() => column.onImageEnlarge(row[column.key])}
            className="absolute right-0 top-0 bottom-0 h-full bg-black bg-opacity-50 w-20">

        </button>
    </div> : null
}
