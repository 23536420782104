import React, {useState, useEffect} from 'react'
import {supabase} from './supabaseClient'
import Navbar from "./components/NavBar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Auth from "./pages/Auth";
import Supplier from "./pages/BuyerDept/Supplier";
import Brand from "./pages/BuyerDept/Brand";
import Category from "./pages/BuyerDept/Category";
import PurchaseOrder from "./pages/BuyerDept/PurchaseOrder";
import Products from "./pages/Products/Products";
import OutOfStock from "./pages/Products/OutOfStock";
import Orders from "./pages/Sales/Orders";
import OnHold from "./pages/Sales/OnHold";
import Header from "./components/Header";
import Account from "./pages/Account";
import Loading from "./components/Loading";
import Promotions from "./pages/Products/Promotions";
import {useDispatch, useSelector} from "react-redux";
// import Users from "./pages/Admin/Users";
import RoleTypes from "./constants/RoleTypes";
import Warehouse from "./pages/Products/Warehouse";

function App() {
    const [session, setSession] = useState(null)
    const loading = useSelector((state) => state.loading);
    const dispatch = useDispatch()

    async function initiateSupabase()
    {
        const {data, error} = await supabase.auth.getSession()

        if (error) {
            alert(error.message)
        }

        setSession(data.session)

        if (data.session) {
            dispatch({type: 'SET_USER', payload: data.session.user})

            await getProfile()
        }

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })

        dispatch({type: 'SET_LOADING', payload: false})
    }

    async function getProfile() {
        const session = await supabase.auth.getSession()

        const {data, error} = await supabase
            .from('profiles')
            .select('*')
            .eq('id', session.data.session.user.id)
            .single()

        if (error) {
            alert(error.message)
        }

        if (!data) {
            dispatch({type: 'SET_PROFILE', payload: {
                    role: RoleTypes.BUYER,
                }})
        } else {
            dispatch({type: 'SET_PROFILE', payload: data})
        }
    }

    useEffect(() => {
        initiateSupabase()
    }, [])
  return (
    <div>
        <div>
            <BrowserRouter>
                {
                    session ? <Header/> : null
                }
                <div>
                    {
                        session ? <Navbar/> : null
                    }
                    <Routes>
                        <Route path="/" element={session ? <Home/> : <Auth/>}/>
                        <Route path="/account" element={session ? <Account/> : <Auth/>}/>
                        <Route path="/supplier" element={session ? <Supplier/> : <Auth/>}/>
                        <Route path="/brand" element={session ? <Brand/> : <Auth/>}/>
                        <Route path="/category" element={session ? <Category/> : <Auth/>}/>
                        <Route path="/purchase-order" element={session ? <PurchaseOrder/> : <Auth/>}/>
                        <Route path="/products" element={session ? <Products/> : <Auth/>}/>
                        <Route path="/out-of-stock" element={session ? <OutOfStock/> : <Auth/>}/>
                        <Route path="/product-promotions" element={session ? <Promotions/> : <Auth/>}/>
                        <Route path="/warehouses" element={session ? <Warehouse/> : <Auth/>}/>
                        <Route path="/orders" element={session ? <Orders/> : <Auth/>}/>
                        <Route path="/on-hold" element={session ? <OnHold/> : <Auth/>}/>
                        {/*<Route path="/admin/users" element={session ? <Users/> : <Auth/>}/>*/}
                    </Routes>
                </div>
            </BrowserRouter>
        </div>
        {
            loading ? <Loading/> : null
        }
    </div>
  );
}

export default App;
