import {useEffect, useState} from "react";
import DefaultCellRenderer from "../DefaultCellRenderer";
import {useSelector} from "react-redux";
import {emitCustomEvent} from "react-custom-events";

export default function DefaultEditCellRenderer({column, row}) {
    const [editable, setEditable] = useState(false)
    const editables = useSelector((state) => state.editableColumns ?? [])

    useEffect(() => {
        setEditable(editables.find((c) => c.column === column.key && c.editable))
    }, []);

    if (!editable) {
        return <DefaultCellRenderer column={column} row={row}/>
    }

    return <input
        autoFocus
        className="w-full pl-2"
        type={column.type ?? 'text'}
        defaultValue={row[column.key] ?? ''}
        onBlur={(event) => emitCustomEvent('DataGrid.RowUpdated', {
            id: row.id,
            key: column.key,
            value: event.target.value,
        })}
    />
}
