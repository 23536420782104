import {useEffect, useState} from "react";

export default function DateCellRenderer({column, row}) {
    const [value, setValue] = useState(null);
    useEffect(() => {
        const split = column.key.split('.')
        const date = new Date(row[split[0]]);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();

        if (isNaN(year) || isNaN(month) || isNaN(day)) setValue(row[split[0]]);

        setValue(`${month}/${day}/${year}`)
    }, []);
    return (<span>{value}</span>)
}
