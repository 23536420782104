import {supabase} from "../supabaseClient";
export async function create(table, data) {
    return supabase.from(table).insert(data).select().single()
}
export async function createMultiple(table, data) {
    return supabase.from(table).insert(data).select()
}

export async function update(table, data) {
    return supabase.from(table).update(data).select()
}

export async function remove(table, column, value) {
    return supabase.from(table).delete().eq(column, value)
}

export async function get(table, select = '*') {
    const {data, error} = await supabase.from(table).select(select)
    if (error) {
        alert(error)
    }
    return data
}

export async function getOne(table, data) {
    return supabase.from(table).select(data).single()
}

export async function getMany(table, data) {
    return supabase.from(table).select(data).then(({data, error}) => {
        if (error) {
            alert(error)
        } else {
            return data
        }
    })
}

export async function getManyWithCount(table, data) {
    return supabase.from(table).select(data).then(({data, error}) => {
        if (error) {
            alert(error)
        } else {
            return data
        }
    })
}

export async function attach(table, data) {
    return supabase.from(table).insert(data)
}
