import Modal from "./Modal";
import {Button} from "@material-tailwind/react";

export default function ImagePreviewModal({
    show,
    image,
    onClose
})
{
    function getUrl()
    {
        return process.env.REACT_APP_SUPABASE_URL + image
    }
    return (<Modal
        show={show}
        footer={null}
        onClose={() => onClose()}
    >
        <img src={getUrl()} alt="preview" className="w-full rounded-lg"/>
        <div className="flex justify-end pt-5">
            <Button
                color="amber"
                onClick={() => onClose()}
                children={'Close'}/>
        </div>
    </Modal>)
}
