import {emitCustomEvent, useCustomEventListener} from "react-custom-events";
import {useRef} from "react";

export default function SelectFilterHeaderCellRenderer({column}) {
    const inputRef = useRef(null)

    function change(event) {
        emitCustomEvent('DataGrid.FilterUpdated', {
            key: column.key,
            value: event.target.value,
        })
    }

    useCustomEventListener('DataGrid.ClearFilters', () => {
        inputRef.current.value = 'None'
    })

    return <div className='min-w-[200px]'>
        <div className='border-b-2 p-2'>
            {column.name}
        </div>
        <div className='w-full px-1'>
            <select
                ref={inputRef}
                className='h-9 w-full px-2 font-normal'
                onChange={(event) => change(event)}
            >
                <option value='None'>None</option>
                {
                    column.options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)
                }
            </select>
        </div>
    </div>
}
