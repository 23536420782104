import DataGrid from "../../components/DataGrid";
import PageView from "../../components/PageView";
import {useState} from "react";
import Modal from "../../components/Modal";
import {Button, Card} from "@material-tailwind/react";
import DefaultInput from "../../components/Form/DefaultInput";
import SupabaseSelectInput from "../../components/Form/SupabaseSelectInput";
import FormButtons from "../../components/Form/FormButtons";
import {submit, validate} from "../../common/Form";
import {create, createMultiple, remove} from "../../common/Supabase";
import ExpandCell from "../../components/DataGrid/ExpandCell";
import DefaultCellRenderer from "../../components/DataGrid/DefaultCellRenderer";
import DateCellRenderer from "../../components/DataGrid/DateCellRenderer";
import DeleteCell from "../../components/DataGrid/DeleteCell";
import EditableColumns from "../../components/EditableColumns";
import {emitCustomEvent} from "react-custom-events";
import DefaultHeaderCellRendererWithFilters
    from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRendererWithFilters";

export default function Promotions({session}) {
    const [showModal, setShowModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({})
    const [products, setProducts] = useState([])

    const table = 'ProductPromotion'

    const columns = [
        {
            key: 'promo_code',
            name: 'PROMO CODE',
            sortable: true,
            type: 'text',
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'price',
            name: 'PRICE',
            sortable: true,
            type: 'number',
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'expiry_date',
            sortable: true,
            name: 'EXPIRY DATE',
            type: 'date',
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
    ];

    const childColumns = [
        {
            key: 'Product.product_code',
            name: 'PRODUCT CODE',
            type: 'text',
            renderCell: DefaultCellRenderer,
        },
        {
            key: 'Product.product_name_hk',
            name: 'PRODUCT NAME',
            type: 'text',
            renderCell: DefaultCellRenderer
        },
        {
            key: 'Product.product_eng_name',
            name: 'PRODUCT ENG NAME',
            type: 'text',
            renderCell: DefaultCellRenderer
        },
        {
            key: 'created_at',
            name: 'CREATED AT',
            type: 'date',
            renderCell: DateCellRenderer
        },
        DeleteCell({
            onDelete: async (id, row) => {
                const {error} = await remove('ProductPromotionProducts', 'id', id)
                if (error) {
                    alert(error.details)
                }
                emitCustomEvent('refresh.ChildDataGrid.' + row.parentId)
            },
            buttonText: 'Detach',
            canClearFilters: true

        })
    ];

    const childSelect = 'id,created_at, Product:product_id(id, product_code, product_name_hk, product_eng_name)'

    const childTable = 'ProductPromotionProducts'

    const rules = {
        promo_code: {required: true, maxLength: 255, minLength: 3},
        price: {required: true, min: 0, max: 9999999999},
        expiry_date: {required: true},
    }

    const gridColumns = [
        ExpandCell(childTable, 'product_promotion_id', childSelect, childColumns),
        ...columns,
        DeleteCell({
            onDelete: async (id) => {
                await remove(childTable, 'product_promotion_id', id)
                const {error} = await remove(table, 'id', id)
                if (error) {
                    alert(error.details)
                }
                emitCustomEvent('refresh.DataGrid.' + table)
            }
        })
    ]

    async function submit() {

        const errors = validate(form, rules)
        setErrors(errors)

        if (Object.keys(errors).length > 0) return
        if (products.length === 0) {
            setErrors({product_id: ['Please select at least one product']})
            return
        }

        setModalLoading(true)

        const {data, error} =
            await create(table, form)

        await createMultiple('ProductPromotionProducts', products.map(product => ({
            product_id: product,
            product_promotion_id: data.id
        })))

        setModalLoading(false)
        setShowModal(false)
        emitCustomEvent('refresh.DataGrid.' + table)
    }

    return (<PageView>
        <Modal
            loading={modalLoading}
            show={showModal}
            className={'bg-white px-6 py-10'}
            confirmText={'Submit'}
            header={'Add Out Of Stock'}
            onClose={() => setShowModal(false)}
        >
            <Card color="transparent" shadow={false}>
                <form className="w-full">
                    <div className="mb-4 flex flex-col gap-6">
                        <DefaultInput
                            label={columns[0].name}
                            name={columns[0].key}
                            rules={columns[0].rules}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <DefaultInput
                            type='number'
                            label={columns[1].name}
                            name={columns[1].key}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <DefaultInput
                            type='date'
                            label={columns[2].name}
                            name={columns[2].key}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <SupabaseSelectInput
                            label="Products"
                            name={'product_id'}
                            // rules={columns[2].rules}
                            table={'Products'}
                            labelKeys={['product_code', 'product_name_hk', 'product_eng_name', 'created_at']}
                            onChange={(name, value) => setProducts(value)}
                            errors={errors}
                            multiple={true}
                        />
                        <FormButtons
                            onSubmit={() =>
                                submit(
                                    table,
                                    form,
                                    rules,
                                    setErrors,
                                    setModalLoading,
                                    setShowModal,
                                    () => {
                                        emitCustomEvent('refresh.DataGrid.' + table)
                                    }
                                )
                            }
                            onClose={() => setShowModal(false)}
                            loading={modalLoading}
                        />
                    </div>
                </form>
            </Card>
        </Modal>
        <Button onClick={() => setShowModal(true)} color="amber" className="my-2 ml-2">Add</Button>
        <EditableColumns columns={columns} table={table}/>
        <DataGrid
            columns={gridColumns}
            table={table}
        />
    </PageView>)
}
