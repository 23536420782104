import {emitCustomEvent, useCustomEventListener} from "react-custom-events";
import {useEffect, useRef, useState} from "react";

export default function DefaultHeaderCellRendererWithFilters({column, ...q}, dataGrid = 'DataGrid') {
    const inputRef = useRef(null)
    const [desc, setDesc] = useState(true)

    function change(value) {
        emitCustomEvent(`${dataGrid}.FilterUpdated`, {
            key: column.key,
            value: value,
        })
    }

    useEffect(() => {
        if(typeof q.sortDirection !== "undefined") {
            setDesc(q.sortDirection === "DESC")
        }
    }, []);

    useCustomEventListener(`${dataGrid}.ClearFilters`, () => {
        inputRef.current.value = null
    })

    function handleOrder() {
        setDesc(!desc)
        emitCustomEvent(`${dataGrid}.Order`, {
            key: column.key,
            direction: !desc ? 'DESC' : 'ASC',
        })
    }

    return <div className='min-w-[200px]'>
        <div
            className='border-b-2 p-2 relative block w-full text-left'>
            {column.name}
            <button
                onClick={handleOrder}
                className={`absolute right-1 top-3 h-0 w-0 border-x-8 border-x-transparent border-b-[16px] border-b-black transform ${desc ? 'rotate-180' : ''}`}
            ></button>
        </div>
        <div className='w-full px-1'>
            <input
                ref={inputRef}
                type={column.type}
                className='h-9 w-full px-2 font-normal'
                onChange={(event) => change(event.target.value)}
            />
        </div>
    </div>
}
