import React from 'react';
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom'

const Navbar = () => {
    const location = useLocation();

    return (
        <aside id="sidebar" className="z-20 h-full pt-16 flex lg:flex flex-shrink-0 flex-col w-full lg:w-1/6 transition-width duration-75 lg:fixed left-0" aria-label="Sidebar">
            <div className="relative flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white pt-0">
                <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                    <div className="flex-1 px-3 bg-white divide-y space-y-1">
                        <ul className="space-y-2 pb-2">
                            {/*<li>*/}
                            {/*    <form action="#" method="GET" className="lg:hidden">*/}
                            {/*        <label htmlFor="mobile-search" className="sr-only">Search</label>*/}
                            {/*        <div className="relative">*/}
                            {/*            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">*/}
                            {/*                <svg className="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                    <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>*/}
                            {/*                </svg>*/}
                            {/*            </div>*/}
                            {/*            <input type="text" name="email" id="mobile-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-cyan-600 focus:ring-cyan-600 block w-full pl-10 p-2.5" placeholder="Search"/>*/}
                            {/*        </div>*/}
                            {/*    </form>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                            {/*    <Link*/}
                            {/*        to="/" className="text-base text-gray-900 font-normal rounded-lg flex items-center p-2 hover:bg-gray-100 group">*/}
                            {/*        <svg className="w-6 h-6 text-gray-500 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*            <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>*/}
                            {/*            <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>*/}
                            {/*        </svg>*/}
                            {/*        <span className="ml-3">Home</span>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*<li className="space-y-2 pt-2">*/}
                            {/*    <h2 className="text-xl font-normal whitespace-nowrap">*/}
                            {/*        Buyer Dept.*/}
                            {/*    </h2>*/}

                            {/*    <div className="flex flex-col">*/}
                            {/*        <Link*/}
                            {/*            to="/supplier"*/}
                            {/*            className={`text-base ${location.pathname === '/warehouses' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}*/}
                            {/*        >*/}
                            {/*            <svg className="w-6 h-6 text-gray-500 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>*/}
                            {/*                <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>*/}
                            {/*            </svg>*/}
                            {/*            <span className="ml-3 flex-1 whitespace-nowrap">Supplier</span>*/}
                            {/*        </Link>*/}
                            {/*        <Link*/}
                            {/*            to="/purchase-order"*/}
                            {/*            className={`text-base ${location.pathname === '/warehouses' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}*/}
                            {/*        >*/}
                            {/*            <svg className="w-6 h-6 text-gray-500 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>*/}
                            {/*                <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd"></path>*/}
                            {/*            </svg>*/}
                            {/*            <span className="ml-3 flex-1 whitespace-nowrap">Purchase Order</span>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*</li>*/}
                            <li className="space-y-2 pt-2">
                                <h2 className="text-xl font-normal whitespace-nowrap">
                                    Products
                                </h2>

                                <div className="flex flex-col">
                                    <Link
                                        to="/products"
                                        className={`text-base ${location.pathname === '/products' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}
                                    >
                                        <svg className="w-6 h-6 fill-current flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z" clipRule="evenodd"></path>
                                        </svg>
                                        <span className="ml-3 flex-1 whitespace-nowrap">Products</span>
                                    </Link>
                                    {/*<Link to="/incoming-orders" className="text-base text-gray-900 font-normal rounded-lg hover:bg-gray-100 group transition duration-75 flex items-center p-2">*/}
                                    {/*    <svg className="w-6 h-6 text-gray-500 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                                    {/*        <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>*/}
                                    {/*        <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>*/}
                                    {/*    </svg>*/}
                                    {/*    <span className="ml-4">Incoming Orders</span>*/}
                                    {/*</Link>*/}
                                    <Link
                                        to="/brand"
                                        className={`text-base ${location.pathname === '/brand' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}
                                    >
                                        <svg className="w-5 h-5 flex-shrink-0 fill-current group-hover:text-gray-900 transition duration-75" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M378.7 32H133.3L256 182.7L378.7 32zM512 192l-107.4-141.3L289.6 192H512zM107.4 50.67L0 192h222.4L107.4 50.67zM244.3 474.9C247.3 478.2 251.6 480 256 480s8.653-1.828 11.67-5.062L510.6 224H1.365L244.3 474.9z"></path>
                                        </svg>
                                        <span className="ml-4">Brand</span>
                                    </Link>
                                    <Link
                                        to="/category"
                                        className={`text-base ${location.pathname === '/category' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}
                                    >
                                        <svg className="w-6 h-6 fill-current flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                                        </svg>
                                        <span className="ml-3 flex-1 whitespace-nowrap">Category</span>
                                    </Link>
                                    <Link
                                        to="/out-of-stock"
                                        className={`text-base ${location.pathname === '/out-of-stock' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}
                                    >
                                        <svg className="w-6 h-6 fill-current flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd"></path>
                                        </svg>
                                        <span className="ml-3 flex-1 whitespace-nowrap">Out of Stock</span>
                                    </Link>
                                    <Link
                                        to="/product-promotions"
                                        className={`text-base ${location.pathname === '/product-promotions' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}
                                    >
                                        <svg className="w-5 h-5 fill-current flex-shrink-0 group-hover:text-gray-900 transition duration-75" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gem" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M378.7 32H133.3L256 182.7L378.7 32zM512 192l-107.4-141.3L289.6 192H512zM107.4 50.67L0 192h222.4L107.4 50.67zM244.3 474.9C247.3 478.2 251.6 480 256 480s8.653-1.828 11.67-5.062L510.6 224H1.365L244.3 474.9z"></path>
                                        </svg>
                                        <span className="ml-3 flex-1 whitespace-nowrap">Promotions</span>
                                    </Link>
                                    <Link
                                        to="/warehouses"
                                        className={`text-base ${location.pathname === '/warehouses' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}

                                    >
                                        <svg
                                            className={`w-5 h-5 flex-shrink-0 group-hover:text-gray-900 transition duration-75 fill-current`}
                                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0Z"/><path d="M7.77 6.76L6.23 5.48 .82 12l5.41 6.52 1.54-1.28L3.42 12l4.35-5.24ZM7 13h2v-2H7v2Zm10-2h-2v2h2v-2Zm-6 2h2v-2h-2v2Zm6.77-7.52l-1.54 1.28L20.58 12l-4.35 5.24 1.54 1.28L23.18 12l-5.41-6.52Z"/></svg>
                                        <span className="ml-3 flex-1 whitespace-nowrap">Warehouses</span>
                                    </Link>
                                </div>
                            </li>
                            <li className="space-y-2 pt-2">
                                <h2 className="text-xl font-normal whitespace-nowrap">
                                    Sales
                                </h2>

                                <div className="flex flex-col">
                                    <Link
                                        to="/orders"
                                        className={`text-base ${location.pathname === '/orders' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}
                                    >
                                        <svg className="w-6 h-6 fill-current flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd"></path>
                                        </svg>
                                        <span className="ml-3 flex-1 whitespace-nowrap">Orders</span>
                                    </Link>
                                    <Link to="/on-hold"
                                          className={`text-base ${location.pathname === '/on-hold' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}
                                    >
                                        <svg className="w-6 h-6 fill-current flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                                        </svg>
                                        <span className="ml-4">On Hold</span>
                                    </Link>
                                </div>
                            </li>

                            {/*<li*/}
                            {/*    className="space-y-2 pt-2"*/}
                            {/*    style={{display: isAdmin ? 'block' : 'none'}}*/}
                            {/*>*/}
                            {/*    <h2 className="text-xl font-normal whitespace-nowrap">*/}
                            {/*        Admin*/}
                            {/*    </h2>*/}

                            {/*    <div className="flex flex-col">*/}
                            {/*        <Link*/}
                            {/*            to="/admin/users"*/}
                            {/*            className={`text-base ${location.pathname === '/warehouses' ? 'text-gray-900' : 'text-gray-500'} font-normal rounded-lg hover:text-gray-900 flex items-center p-2 group `}*/}
                            {/*        >*/}
                            {/*            <svg className="w-6 h-6 text-gray-500 flex-shrink-0 group-hover:text-gray-900 transition duration-75" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                <path fillRule="evenodd" d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z" clipRule="evenodd"></path>*/}
                            {/*            </svg>*/}
                            {/*            <span className="ml-3 flex-1 whitespace-nowrap">Users</span>*/}
                            {/*        </Link>*/}
                            {/*    </div>*/}
                            {/*</li>*/}

                        </ul>
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default Navbar;
