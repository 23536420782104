
export default function ProductCodeCellRenderer({column, row})
{
    return row[column.key] ? <div className="relative h-full">
        {/*<span className="pl-2">{value}</span>*/}
        <button
            onClick={() => column.onProductCodeClick(row['id'])}
            className="absolute right-0 top-0 bottom-0 h-full bg-opacity-50 w-14 flex items-center justify-end pr-2">
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h20v20H0Z"/><g><path d="M11 10L8 7v2.5H3.5c-.28 0-.5.22-.5.5s.22.5.5.5H8V13l3-3Z"/><path d="M11.5 8h5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-5c-.28 0-.5.22-.5.5s.22.5.5.5Z"/><path d="M16.5 12h-5c-.28 0-.5.22-.5.5s.22.5.5.5h5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5Z"/><path d="M16.5 9.5h-3c-.28 0-.5.22-.5.5s.22.5.5.5h3c.28 0 .5-.22.5-.5s-.22-.5-.5-.5Z"/></g></svg>
        </button>
    </div> : null
}
