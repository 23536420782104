import { combineReducers } from 'redux';
import authReducer from "./auth/authReducer";
import loadingReducer from "./loadingReducer";
import editableColumnsReducer from "./editableColumnsReducer";

const rootReducers = combineReducers({
    loading: loadingReducer,
    auth: authReducer,
    editableColumns: editableColumnsReducer,
});
export default rootReducers;
