import {Button} from "@material-tailwind/react";
import Spinner from "../Spinner";

export default function FormButtons({onClose, onSubmit, loading, className, submitText = 'Submit'}) {
    return (<div className={"flex justify-end " + className}>
        <Button
            variant="text"
            color="red"
            onClick={onClose}
            disabled={loading}
            className="mr-1"
        >
            <span>Cancel</span>
        </Button>
        <Button
            disabled={loading}
            variant="gradient"
            color="green"
            onClick={onSubmit}
            className="disabled:opacity-50"
        >
            <span className={loading ? 'hidden' : ''}>{submitText}</span>
            <Spinner width={10} className={loading ? '' : 'hidden'}/>
        </Button>
    </div>)
}
