import PageView from "../components/PageView";
import {useEffect} from "react";
import { useNavigate } from "react-router-dom";

export default function Home({session}) {
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/products')
    }, []);

    return (
        <PageView>
            <div>
                Home
            </div>
        </PageView>
    )
}
