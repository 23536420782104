import actionTypes from './actionTypes';
import RoleTypes from "../../constants/RoleTypes";

const initialState = {
    user: null,
    profile: null,
    role: null,
    isAdmin: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.payload
            };
        case actionTypes.SET_PROFILE:
            return {
                ...state,
                profile: action.payload,
                role: action.payload.role,
                isAdmin: action.payload.role === RoleTypes.ADMIN,
            };
        default:
            return state;
    }
};
