import ChildDataGrid from "../../components/ChildDataGrid";

export default function ExpandCell (table, parentKey, select, columns) {
    return {
        key: 'expand',
        type: 'expand',
        name: '',
        minWidth: 30,
        index: 1,
        width: 30,
        isCellEditable: false,
        colSpan(args) {
            return args.type === 'ROW' && args.row.type === 'DETAIL' ? 100 : undefined;
        },
        cellClass(row) {
            return row.type === 'DETAIL'
                ? 'p-2'
                : undefined;
        },
        renderCell({row, onRowChange}) {
            if (row.type === 'DETAIL') {
                return <ChildDataGrid parentId={row.parentId} parentKey={parentKey} table={table} select={select} columns={columns}/>
            }

            return (<button
                onClick={() => onRowChange({ ...row, expanded: !row.expanded })}>
                {
                    row.expanded ? '\u25BC' : '\u25B6'
                }
            </button>);
        }
    }
}
