import PageView from "../../components/PageView";

export default function OnHold({session}) {
    return (
        <PageView>
            <div className="form-widget">
                On Hold
            </div>
        </PageView>
    )
}
