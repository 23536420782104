import {default as DataGridOriginal} from "react-data-grid";
import {useEffect, useRef, useState} from "react";
import {supabase} from "../supabaseClient";
import Spinner from "./Spinner";
import {getPagination} from "../common/DataGrid/Pagination";
import EmptyRowsRenderer from "./DataGrid/EmptyRowsRenderer";
import {emitCustomEvent, useCustomEventListener} from 'react-custom-events'

export default function ChildDataGrid({parentId, table, parentKey, select, columns}) {
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const gridRef = useRef(null);
    // const [total, setTotal] = useState(null)
    const [page] = useState(1)
    const [limit] = useState(20)
    const [order] = useState({
        column: 'id',
        ascending: false,
    })
    const [filters, setFilters] = useState({})

    useCustomEventListener('refresh.ChildDataGrid.' + parentId, (data) => {
            getResults()
        }
    )

    useCustomEventListener('ChildDataGrid.FilterUpdated', (data) => {
        setFilters(prevState => ({
            ...prevState,
            [data.key]: data.value
        }))
        getResults(
            {
                ...filters,
                [data.key]: data.value
            }
        )
    })

    useCustomEventListener('ChildDataGrid.Order', (data) => {
        getResults(
            null,
            {
                column: data.key,
                ascending: data.direction === 'ASC'
            }
        )
    })

    useEffect(() => {
        getResults()
    }, []);

    async function getResults(filters = null, order = null) {
        setLoading(true)

        const {from, to} = getPagination(page, limit)

        const query = supabase
            .from(table)
            .select(select)
            .limit(limit)
            .range(from, to)
            .eq(parentKey, parentId)

        if (order) {
            query.order(order.column, {ascending: order.ascending})
        }

        if (filters && Object.keys(filters).length > 0) {
            Object.entries(filters).forEach((filter) => {
                if (filter[1] === 'None' || filter[1] === '' || filter[1] === null) {
                    return
                }
                if (filter[1].includes('-')) {
                    const date1 = new Date(filter[1]);
                    const date2 = new Date();

                    date2.setDate(date1.getDate() + 1)

                    const dateString1 = date1.toISOString();
                    const dateString2 = date2.toISOString();

                    query.lt(filter[0], dateString2)
                    query.gt(filter[0], dateString1)
                } else if (!isNaN(parseInt(filter[1]))) {
                    query.filter(filter[0], 'eq', filter[1])
                } else {
                    query.filter(filter[0], 'like', '%' + filter[1] + '%')
                }
            })
        }

        const {data, error} = await query

        if (error) {
            alert(error.message)
        }

        setRows([])

        setRows(data.map(row => {
            row.parentId = parentId
            return row
        }))

        setLoading(false)
    }

    return (<div className={'relative'}>
        <DataGridOriginal
            ref={gridRef}
            rows={rows}
            columns={columns}
            // rowKeyGetter={rowKeyGetter}
            style={{blockSize: 250}}
            // direction={direction}
            rowHeight={48}
            headerRowHeight={80}
            renderers={{noRowsFallback: <EmptyRowsRenderer/>}}
        />
        {
            loading
                ? <span className="absolute top-0 left-0 right-0 p-10 flex justify-center"><Spinner/></span>
                : ''
        }
    </div>)
}
