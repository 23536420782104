import {useState} from "react";

const Pagination = ({total, limit, onNextPage = null, onPrevPage = null, onSetPage = null, onSetPerPage = null, className = ""}) => {
    const [current, setCurrent] = useState(1)

    let pages = Math.ceil(total / limit)

    let previous = [current - 2, current - 1]
    let next = [current + 1, current + 2]

    function nextPage() {
        if (current >= pages) return
        setCurrent(current + 1)
        onNextPage(current + 1)
    }

    function prevPage() {
        if (current <= 1) return
        setCurrent(current - 1)
        if (onPrevPage === null) return
        onPrevPage(current + 1)
    }

    function setPage(page) {
        if (page <= 0 || page > pages) return
        if (onPrevPage === null) return
        setCurrent(page)
        onSetPage(page)
    }

    function setPerPage(value) {
        if (onPrevPage === null) return
        onSetPerPage(parseInt(value))
        setCurrent(1)
    }

    if (total === null || total < limit) return null

    return (
        <div className={`bg-white pb-4 r w-full flex justify-center ${className}`}>
            <ul className="flex items-center -mx-[6px]">
                {
                    current > 1
                        ? <li className="px-[6px]">
                            <button
                                onClick={prevPage}
                                className="w-9 h-9 flex items-center justify-center rounded-md border border-[#EDEFF1] text-[#838995] text-base hover:bg-primary hover:border-primary hover:text-white">
                            <span>
                                <svg width="8" height="15" viewBox="0 0 8 15" className="fill-current stroke-current">
                                    <path
                                        d="M7.12979 1.91389L7.1299 1.914L7.1344 1.90875C7.31476 1.69833 7.31528 1.36878 7.1047 1.15819C7.01062 1.06412 6.86296 1.00488 6.73613 1.00488C6.57736 1.00488 6.4537 1.07206 6.34569 1.18007L6.34564 1.18001L6.34229 1.18358L0.830207 7.06752C0.830152 7.06757 0.830098 7.06763 0.830043 7.06769C0.402311 7.52078 0.406126 8.26524 0.827473 8.73615L0.827439 8.73618L0.829982 8.73889L6.34248 14.6014L6.34243 14.6014L6.34569 14.6047C6.546 14.805 6.88221 14.8491 7.1047 14.6266C7.30447 14.4268 7.34883 14.0918 7.12833 13.8693L1.62078 8.01209C1.55579 7.93114 1.56859 7.82519 1.61408 7.7797L1.61413 7.77975L1.61729 7.77639L7.12979 1.91389Z"
                                        strokeWidth="0.3"></path></svg>
                            </span>
                            </button>
                        </li>
                        : null}

                {
                    previous.map((item, index) => {
                        if (item >= 1) {
                            return (
                                <li key={index} className="px-[6px]">
                                    <button
                                        onClick={() => setPage(item)}
                                        className="w-9 h-9 flex items-center justify-center rounded-md border border-[#EDEFF1] text-[#838995] text-base hover:bg-primary hover:border-primary hover:text-white">
                                        {item}
                                    </button>
                                </li>
                            )
                        }
                    })
                }

                <li className="px-[6px]">
                    <button
                        disabled
                        className="w-9 h-9 flex items-center justify-center rounded-md border text-base hover:bg-primary border-gray-400">
                        {current}
                    </button>
                </li>

                {
                    next.map((item, index) => {
                        if (item <= pages) {
                            return (
                                <li key={index} className="px-[6px]">
                                    <button
                                        onClick={() => setPage(item)}
                                        className="w-9 h-9 flex items-center justify-center rounded-md border border-[#EDEFF1] text-[#838995] text-base hover:bg-primary hover:border-primary hover:text-white">
                                        {item}
                                    </button>
                                </li>
                            )
                        }
                    })
                }

                {
                    current >= pages
                        ? null
                        : <li className="px-[6px]">
                            <button
                                onClick={nextPage}
                                className="w-9 h-9 flex items-center justify-center rounded-md border border-[#EDEFF1] text-[#838995] text-base hover:bg-primary hover:border-primary hover:text-white">
                                <span>
                                    <svg width="8" height="15" viewBox="0 0 8 15"
                                         className="fill-current stroke-current"><path
                                        d="M0.870212 13.0861L0.870097 13.086L0.865602 13.0912C0.685237 13.3017 0.684716 13.6312 0.895299 13.8418C0.989374 13.9359 1.13704 13.9951 1.26387 13.9951C1.42264 13.9951 1.5463 13.9279 1.65431 13.8199L1.65436 13.82L1.65771 13.8164L7.16979 7.93248C7.16985 7.93243 7.1699 7.93237 7.16996 7.93231C7.59769 7.47923 7.59387 6.73477 7.17253 6.26385L7.17256 6.26382L7.17002 6.26111L1.65752 0.398611L1.65757 0.398563L1.65431 0.395299C1.454 0.194997 1.11779 0.150934 0.895299 0.373424C0.695526 0.573197 0.651169 0.908167 0.871667 1.13067L6.37922 6.98791C6.4442 7.06886 6.43141 7.17481 6.38592 7.2203L6.38587 7.22025L6.38271 7.22361L0.870212 13.0861Z"
                                        strokeWidth="0.3"></path></svg>
                                </span>
                            </button>
                        </li>
                }

            </ul>
            <div className="flex items-center pl-3 text-[#838995]">
                Per Page:
                <select
                    onChange={(event) => setPerPage(event.target.value)}
                    defaultValue={limit}
                >
                    <option selected={limit === 5} value="5">5</option>
                    <option selected={limit === 10} value="10">10</option>
                    <option selected={limit === 20} value="20">20</option>
                    <option selected={limit === 50} value="50">50</option>
                    <option selected={limit === 100} value="100">100</option>
                </select>
            </div>
        </div>
    )
}

export default Pagination
