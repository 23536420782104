import DataGrid from "../../components/DataGrid";
import PageView from "../../components/PageView";
import DefaultCellRenderer from "../../components/DataGrid/DefaultCellRenderer";
import DateCellRenderer from "../../components/DataGrid/DateCellRenderer";
import {useState} from "react";
import Modal from "../../components/Modal";
import {Button, Card} from "@material-tailwind/react";
import DefaultInput from "../../components/Form/DefaultInput";
import SelectInput from "../../components/Form/SelectInput";
import SupabaseSelectInput from "../../components/Form/SupabaseSelectInput";
import FormButtons from "../../components/Form/FormButtons";
import {remove} from "../../common/Supabase";
import {submit} from "../../common/Form";
import EditableColumns from "../../components/EditableColumns";
import DefaultEditCellRenderer from "../../components/DataGrid/EditCells/DefaultEditCellRenderer";
import SelectCellRenderer from "../../components/DataGrid/SelectCellRenderer";
import SelectEditCellRenderer from "../../components/DataGrid/EditCells/SelectEditCellRenderer";
import DefaultEditCell from "../../components/DataGrid/EditCells/ImageEditCellRenderer";
import DeleteCell from "../../components/DataGrid/DeleteCell";
import {emitCustomEvent} from "react-custom-events";
import DefaultHeaderCellRendererWithFilters
    from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRendererWithFilters";
import DefaultHeaderCellRenderer from "../../components/DataGrid/HeaderCells/DefaultHeaderCellRenderer";
import SelectFilterHeaderCellRenderer from "../../components/DataGrid/HeaderCells/SelectFilterHeaderCellRenderer";

export default function OutOfStock() {
    const [showModal, setShowModal] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [form, setForm] = useState({})

    const table = 'OutOfStock'

    const columns = [
        {
            key: 'quantity',
            name: 'QTY',
            type: 'number',
            sortable: true,
            renderCell: DefaultCellRenderer,
            renderEditCell: DefaultEditCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
        {
            key: 'status',
            name: 'STATUS',
            type: 'select',
            sortable: true,
            options: [
                {value: 'out_of_stock', label: 'Out of Stock'},
                {value: 'partial_received', label: 'Partial Received'},
                {value: 'completed', label: 'Completed'},
            ],
            rules: {required: true},
            default: 'out_of_stock',
            renderCell: SelectCellRenderer,
            renderEditCell: SelectEditCellRenderer,
            renderHeaderCell: SelectFilterHeaderCellRenderer,
        },
        {
            key: 'created_at',
            name: 'DATE CREATED',
            type: 'date',
            sortable: true,
            renderCell: DateCellRenderer,
            renderHeaderCell: DefaultHeaderCellRendererWithFilters,
        },
    ];

    const select = '*, Products:product_id (product_code, product_name_hk, created_at)';

    const rules = {
        quantity: {required: true, min: 1, max: 1000000},
        status: {required: true, in: ['out_of_stock', 'partial_received', 'completed']},
        product_id: {required: true},
    }

    const gridColumns = [
        ...columns,
        {
            key: 'Products.product_id',
            name: 'PRODUCT CODE',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderHeaderCell: DefaultHeaderCellRenderer,
        },
        {
            key: 'Products.product_name_hk',
            name: 'PRODUCT HK NAME',
            type: 'text',
            renderCell: DefaultCellRenderer,
            renderHeaderCell: DefaultHeaderCellRenderer,
        },
        DeleteCell({
            onDelete: async (id) => {
                const {error} = await remove(table, 'id', id)
                if (error) {
                    alert(error.details)
                }
                emitCustomEvent('refresh.DataGrid.' + table)
            },
            canClearFilters: true
        })
    ]

    return (<PageView>
        <Modal
            loading={modalLoading}
            show={showModal}
            className={'bg-white px-6 py-10'}
            confirmText={'Submit'}
            header={'Add Out Of Stock'}
            onClose={() => setShowModal(false)}
        >
            <Card color="transparent" shadow={false}>
                <form className="w-full">
                    <div className="mb-4 flex flex-col gap-6">
                        <DefaultInput
                            type={'number'}
                            label={columns[0].name}
                            name={columns[0].key}
                            rules={columns[0].rules}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <SelectInput
                            label={columns[1].name}
                            name={columns[1].key}
                            rules={columns[1].rules}
                            options={columns[1].options}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <SupabaseSelectInput
                            label="Products"
                            name={'product_id'}
                            table={'Products'}
                            labelKeys={['product_code', 'product_name_hk', 'product_eng_name', 'created_at']}
                            onChange={(name, value) => setForm({...form, [name]: value})}
                            errors={errors}
                        />
                        <FormButtons
                            onSubmit={() =>
                                submit(
                                    table,
                                    form,
                                    rules,
                                    setErrors,
                                    setModalLoading,
                                    setShowModal,
                                    () => {
                                        emitCustomEvent('refresh.DataGrid.' + table)
                                    }
                                )
                            }
                            onClose={() => setShowModal(false)}
                            loading={modalLoading}
                        />
                    </div>
                </form>
            </Card>
        </Modal>
        <Button onClick={() => setShowModal(true)} color="amber" className="my-2 ml-2">Add</Button>
        <EditableColumns columns={columns} table={table}/>
        <DataGrid
            table={table}
            select={select}
            columns={gridColumns}
        />
    </PageView>)
}
